import React, { useState } from "react";
import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader } from "rsuite";
import Swal from "sweetalert2";
import { queryClient } from "../../../App";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { COUNTRIES } from "../../../util/Countries";
import { DebounceFun } from "../../../util/DebounceFun";
import { UploadToS3 } from "../../../util/UploadToS3";
import { AddNewStudent } from "../../../API/Students/AddNewStudent";
import { CheckEmailPhoneTeacher } from "../../../API/CheckEmailPhoneTeacher";
import { GetCommunityEDucationLevels } from "../../../API/GetCommunityEDucationLevel";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
const debouncedCheckEmail = DebounceFun(
  (email) => CheckEmailPhoneTeacher(email, ""),
  500
);
const debouncedCheckPhone = DebounceFun(
  (phone) => CheckEmailPhoneTeacher("", phone),
  500
);

const loginSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "يجب أن يتكون الاسم الأول من 3 أحرف على الأقل")
    .max(50, "يجب ألا يتجاوز الاسم الأول 50 حرفًا")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "يجب أن يحتوي الاسم الأول على أحرف وأرقام ويمكن أن يتضمن الشرطات أو الشرطات السفلية"
    )
    .required("الرجاء إدخال اسم المستخدم"),
  lastName: Yup.string()
    .min(3, "يجب أن يتكون الاسم الأخير من 3 أحرف على الأقل")
    .max(50, "يجب ألا يتجاوز الاسم الأخير 50 حرفًا")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "يجب أن يحتوي الاسم الأخير على أحرف وأرقام ويمكن أن يتضمن الشرطات أو الشرطات السفلية"
    )
    .required("الرجاء إدخال اسم المستخدم"),
  password: Yup.string()
    .min(5, "يجب أن تتكون كلمة المرور من 5 أحرف على الأقل")
    .max(50, "يجب ألا تتجاوز كلمة المرور 50 حرفًا")
    .required("الرجاء إدخال كلمة المرور")
    .matches(
      passwordRegex,
      "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل، حرف صغير، رقم، وحرف خاص"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "يجب أن تتطابق كلمة المرور")
    .required("الرجاء تأكيد كلمة المرور"),
  email: Yup.string()
    .email("البريد الإلكتروني غير صالح")
    .required("الرجاء إدخال البريد الإلكتروني")
    .test(
      "is-email-unique",
      "البريد الإلكتروني مستخدم بالفعل",
      async function (value) {
        if (!value) return true;
        try {
          const data = await debouncedCheckEmail(value);
          if (data.emailExists) {
            return this.createError({
              path: this.path,
              message: "البريد الإلكتروني مستخدم بالفعل",
            });
          }
          return true;
        } catch (error) {
          console.error("خطأ في التحقق من البريد الإلكتروني:", error);
          return this.createError({
            path: this.path,
            message: "فشل في التحقق من البريد الإلكتروني",
          });
        }
      }
    ),
  phone: Yup.number()
    .required("الرجاء إدخال رقم الهاتف")
    .typeError("يجب أن يكون رقم الهاتف عبارة عن أرقام")
    .positive("يجب أن يكون رقم الهاتف موجبًا")
    .test(
      "is-phone-unique",
      "رقم الهاتف مستخدم بالفعل",
      async function (value) {
        if (!value) return true;
        try {
          const data = await debouncedCheckPhone(value);
          if (data.phoneExists) {
            return this.createError({
              path: this.path,
              message: "رقم الهاتف مستخدم بالفعل",
            });
          }
          return true;
        } catch (error) {
          console.error("خطأ في التحقق من رقم الهاتف:", error);
          return this.createError({
            path: this.path,
            message: "فشل في التحقق من رقم الهاتف",
          });
        }
      }
    )
    .test(
      "isAtLeastTwoDigits",
      "يجب أن يحتوي رقم الهاتف على 11 رقمًا على الأقل",
      (number) => number && number.toString().length > 9
    )
    .test(
      "isAtLeastTwoDigits",
      "يجب ألا يتجاوز رقم الهاتف 11 رقمًا",
      (number) => number && number.toString().length <= 11
    ),
  language: Yup.string().required("الرجاء اختيار اللغة"),

  country: Yup.string()
    .required("الرجاء اختيار الدولة")
    .notOneOf([""], "الرجاء اختيار الدولة"),
  eduLevelId: Yup.string()
    .required("الرجاء اختيار مستوى التعليم")
    .notOneOf([""], "الرجاء اختيار مستوى التعليم"),
  image: Yup.mixed()
    .required("يجب تحميل ملف صورة")
    .test(
      "fileSize",
      "الملف كبير جدًا",
      (value) => value && value.size <= 1024 * 1024 * 5
    ) // حد 5 ميجابايت
    .test(
      "fileFormat",
      "الصيغة غير مدعومة",
      (value) =>
        value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    ),
});

const languages = [
  { value: "Arabic", label: "العربية" },
  { value: "English", label: "الانجليزية" },
];

const customStyles = (error, touched) => ({
  control: (provided) => ({
    ...provided,
    borderColor:
      touched && error
        ? "red"
        : touched && !error
        ? "#7ed321"
        : provided.borderColor,
    "&:hover": {
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
    },
    boxShadow:
      touched && error
        ? "0 0 0 1px red"
        : touched
        ? "0 0 0 1px #7ed321"
        : "none",
  }),
});

const AddStudent = () => {
  const navigate = useNavigate();
  const {
    data: CommunityLevels,
    isLoading: isCommunityLevelsLoading,
    isError: isCommunityLevelsError,
  } = useQuery({
    queryKey: ["CommunityLevels"],
    queryFn: () => GetCommunityEDucationLevels(),
  });

  const { mutate } = useMutation({
    mutationFn: (data) => AddNewStudent(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllStudents"] });
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم اضافة طالب بنجاح",
      });
      navigate("/جميع-الطلاب");
    },
    onError: () => {
      // console.log(error.response.data);
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const LEVELS =
    !isCommunityLevelsLoading &&
    !isCommunityLevelsError &&
    CommunityLevels.map((item) => {
      return { value: item.level, label: item.level, id: item.id };
    });

  return (
    <>
      <PageTitle
        title={"جميع الطلاب"}
        activeMenu={"جميع الطلاب"}
        secondMenu={"اضافة طالب"}
        motherMenu={"الطلاب"}
        goBack
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">معلومات الطالب</h5>
            </div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                password: "",
                confirmPassword: "",
                email: "",
                phone: "",
                language: "",
                country: "",
                address: "",
                city: "",
                eduLevelId: "",
                image: null,
              }}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const { confirmPassword, image, ...restVa } = {
                  ...values,
                };
                mutate(restVa, {
                  onSuccess: (data) => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    UploadToS3(
                      data.image,
                      image,
                      "LMST/TEACHER/IMAGES/PROFILE/"
                    );
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="firstName">
                            الاسم الاول
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="firstName"
                            placeholder="ادخل الاسم الاول"
                            type="text"
                            name="firstName"
                            className={`form-control ${
                              touched.firstName
                                ? errors.firstName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.firstName && errors.firstName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lastName">
                            الاسم الاخير
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lastName"
                            placeholder="ادخل الاسم الاخير"
                            type="text"
                            name="lastName"
                            className={`form-control ${
                              touched.lastName
                                ? errors.lastName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lastName && errors.lastName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Email">
                            البريد الالكتروني
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Email"
                            placeholder="ادخل البريد الالكتروني"
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Phone_Number">
                            رقم الهاتف
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Phone_Number"
                            placeholder="ادخل رقم الهاتف"
                            type="text"
                            name="phone"
                            className={`form-control ${
                              touched.phone
                                ? errors.phone
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phone && errors.phone && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.password
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="Password">
                            كلمة المرور
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="Password"
                              placeholder="ادخل كلمة المرور"
                              type={`${showPassword ? "text" : "password"}`}
                              name="password"
                              className={`form-control ${
                                touched.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.password && errors.password && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.confirmPassword
                              ? errors.confirmPassword
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="confirmPassword">
                            تاكيد كلمة المرور
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="confirmPassword"
                              placeholder="ادخل تاكيد كلمة المرور"
                              type={`${showPassword ? "text" : "password"}`}
                              name="confirmPassword"
                              className={`form-control ${
                                touched.confirmPassword
                                  ? errors.confirmPassword
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.language
                              ? errors.language
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label className="form-label">
                            اللغة
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="language"
                            id="language"
                            placeholder="اختر اللغة"
                            isSearchable={false}
                            options={languages}
                            styles={customStyles(
                              errors.language,
                              touched.language
                            )}
                            className={`custom-react-select ${
                              touched.language
                                ? errors.language
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={languages.find(
                              (option) => option.value === values.language
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "language",
                                option ? option.value : ""
                              );
                              setFieldTouched("language", true, false); // mark as touched but do not validate yet
                              validateField("language"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("language", true)}
                          />
                          {touched.language && errors.language && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="country"
                            className="form-label">
                            الدولة
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="country"
                            id="country"
                            placeholder="اختر الدولة"
                            isSearchable={true}
                            options={COUNTRIES}
                            styles={customStyles(
                              errors.country,
                              touched.country
                            )}
                            className={`custom-react-select ${
                              touched.country
                                ? errors.country
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={COUNTRIES.find(
                              (option) => option.value === values.country
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "country",
                                option ? option.value : ""
                              );
                              setFieldTouched("country", true, false); // mark as touched but do not validate yet
                              validateField("country"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("country", true)}
                          />
                          {touched.country && errors.country && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.country}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="city">
                            المدينة
                          </label>
                          <input
                            id="city"
                            placeholder="ادخل المدينة"
                            type="text"
                            name="city"
                            className={`form-control ${
                              touched.city
                                ? errors.city
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.city && errors.city && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.city}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="address">
                            العنوان
                          </label>
                          <input
                            id="address"
                            placeholder="ادخل العنوان"
                            type="text"
                            name="address"
                            className={`form-control ${
                              touched.address
                                ? errors.address
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.address && errors.address && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.address}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="eduLevelId"
                            className="form-label">
                            الدرجة العلمية
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="eduLevelId"
                            id="eduLevelId"
                            placeholder="اختر الدرجة العلمية"
                            isSearchable={false}
                            options={LEVELS}
                            styles={customStyles(
                              errors.eduLevelId,
                              touched.eduLevelId
                            )}
                            className={`custom-react-select ${
                              touched.eduLevelId
                                ? errors.eduLevelId
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            onChange={(option) => {
                              setFieldValue(
                                "eduLevelId",
                                option ? option.id : ""
                              );
                              setFieldTouched("eduLevelId", true, false); // mark as touched but do not validate yet
                              validateField("eduLevelId"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("eduLevelId", true)}
                          />
                          {touched.eduLevelId && errors.eduLevelId && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.eduLevelId}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="image"
                            className="form-label">
                            صورة الطالب
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className={`form-control ${
                              touched.image
                                ? errors.image
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            type="file"
                            id="image"
                            name="image"
                            onChange={(e) => {
                              setFieldValue("image", e.currentTarget.files[0]);
                            }}
                          />
                          {touched.image && errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "تسجيل"}
                        </button>
                        <button
                          onClick={() => navigate("/جميع-الطلاب")}
                          type="button"
                          className="btn btn-danger light">
                          الغاء
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudent;
