import { Link, useParams } from "react-router-dom";
import PageTitle from "../../../../../layouts/PageTitle";
import AssignmentsSubmissions from "./AssignmentsSubmissions";
import { useState } from "react";
import { DatePicker } from "rsuite";
import { Collapse } from "react-bootstrap";

function AssginmentsPage() {
  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [filters, setFilters] = useState({ search: "", date: "" });

  const { id, submissionId } = useParams();

  const handleFilterChange = () => {
    setFilters({ search, date });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDateChange = (value) => {
    const formattedDate = value
      ? new Date(value).toISOString().slice(0, 10)
      : "";
    setDate(formattedDate);
  };

  const resetFilters = () => {
    setSearch("");
    setDate("");
    setFilters({ search: "", date: "" });
  };

  return (
    <>
      <PageTitle
        activeMenu={"جميع الدورات"}
        title={"الواجبات"}
        motherMenu={"الدورات"}
        secondMenu={"الواجبات"}
        secondId={submissionId}
        thirdMenu={"التسليمات"}
        userId={id}
        goBack
        goTo={`/جميع-الدورات/${id}/الواجبات`}
      />
      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>فلتر
          </div>
          <div className="tools">
            <Link
              to="#"
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>
        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">بحث</label>
                  <input
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleFilterChange();
                      }
                    }}
                    value={search}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    placeholder={"بحث باستخدام اسم الطالب او الرقم التعريفي"}
                  />
                </div>

                <div className="col-xl-3 col-sm-6 d-flex flex-column ">
                  <label className="form-label">تاريخ التسجيل</label>
                  <DatePicker
                    placeholder={"تاريخ التقديم"}
                    className="picker-suit"
                    onChange={handleDateChange}
                    value={date ? new Date(date) : null}
                    format="yyyy-MM-dd"
                  />
                </div>

                <div className="col-xl-3 col-sm-6 align-self-end mt-4">
                  <button
                    onClick={handleFilterChange}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    فلتر <i className="fa fa-search me-1" />
                  </button>
                  <button
                    onClick={resetFilters}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    حذف الفلتر
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <AssignmentsSubmissions
        id={id}
        submissionId={submissionId}
        title={filters.search}
        startDate={filters.date}
      />
    </>
  );
}

export default AssginmentsPage;
