import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Card, Col, Row } from "react-bootstrap";
import { IMAGES } from "../../constant/theme";
import CourseFilter from "./CourseFilter";
import PageTitleButton from "../../layouts/PageTitleButton";
import { useQuery } from "@tanstack/react-query";
import { GetAllCourses } from "../../../API/Courses/GetAllCourses";
import { GetCommunityEDucationLevels } from "../../../API/GetCommunityEDucationLevel";
import Swal from "sweetalert2";
import CustomPagination from "./Pagination";
import { COURSEIMG } from "../../../util/UploadToS3";

const AllCourses = () => {
  const [pageNo, setPageNo] = useState(0);
  const [filters, setFilters] = useState({
    publishDate: "",
    educationLevel: [],
    courseType: "",
    search: "",
  });
  const navigate = useNavigate();
  const itemsPerPage = 8;
  const {
    data: allCourses,
    isLoading: CoursesLoading,
    isError: CoursesError,
    error,
  } = useQuery({
    queryKey: ["AllCourses"],
    queryFn: () => GetAllCourses(),
  });

  const {
    data: CommunityLevels,
    isLoading: isCommunityLevelsLoading,
    isError: isCommunityLevelsError,
  } = useQuery({
    queryKey: ["CommunityLevels"],
    queryFn: () => GetCommunityEDucationLevels(),
  });

  if (CoursesLoading || isCommunityLevelsLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (CoursesError) {
    if (
      error.response.data.error === "In valid email or password" &&
      error.response.status === 403
    ) {
      Swal.fire({
        title: "Oops",
        text: "يجب اعادة تسجيل الدخول!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "العودة الي صفحة تسجيل الدخول",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
      return;
    } else {
      return (
        <Row>
          <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
            حدث خطأ
          </div>
        </Row>
      );
    }
  }

  const LEVELS =
    !isCommunityLevelsLoading &&
    !isCommunityLevelsError &&
    CommunityLevels.map((item) => {
      return { value: item.level, label: item.level, id: item.id };
    });

  const filteredCourses = allCourses.filter((course) => {
    const matchesPublishDate = filters.date
      ? new Date(course.openDate) >= new Date(filters.date)
      : true;
    const matchesEducationLevel =
      filters.educationLevel.length > 0
        ? filters.educationLevel.some(
            (level) => course.eduLevel === level.value
          )
        : true;
    const matchesCourseType = filters.courseType
      ? course.courseType.includes(filters.courseType)
      : true;
    const matchesSearch = filters.search
      ? course.title.toLowerCase().includes(filters.search.toLowerCase())
      : true;
    return (
      matchesPublishDate &&
      matchesEducationLevel &&
      matchesCourseType &&
      matchesSearch
    );
  });

  console.log(filteredCourses);
  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);

  const paginatedCourses = filteredCourses.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPageNo(0);
  };

  return (
    <>
      <PageTitle
        activeMenu={"جميع الدورات"}
        motherMenu={"الدورات"}
      />
      <CourseFilter
        onFilterChange={handleFilterChange}
        educationLevels={LEVELS}
      />
      <PageTitleButton
        activeMenu={"جميع الدورات"}
        buttonText="+ اضافة دورة جديدة"
        whereTo={"/جميع-الدورات/اضافة-دورة"}
        data={LEVELS}
      />
      <Row>
        {!filteredCourses || filteredCourses.length === 0 ? (
          <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
            No courses found
          </div>
        ) : (
          paginatedCourses.map((data, ind) => (
            <Col
              xl={3}
              xxl={4}
              lg={4}
              md={6}
              sm={6}
              key={data.id}>
              <Card
                onClick={() => navigate(`/جميع-الدورات/${data.id}`)}
                className="text-decoration-none ">
                <ImageWithFallback
                  src={`${COURSEIMG}${data.image}?${new Date().getTime()}`}
                  fallbackSrc={IMAGES.courseBroken}
                  alt="CourseImg"
                  className="rounded-top course-img"
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="card-body">
                  <h4>{data.title}</h4>
                  <h6 className="text-center text-primary">{data.subtitle}</h6>
                  <ul className="list-group mb-3 list-group-flush">
                    <li className="list-group-item px-0 border-top-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">
                        الرقم التعريفي :
                      </strong>
                      <strong>#{data.id}</strong>
                    </li>
                    <li className="list-group-item px-0 border-top-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">
                        تاريخ الانشاء :
                      </strong>
                      <strong>
                        {new Intl.DateTimeFormat("ar-EG", {
                          dateStyle: "full",
                        }).format(new Date(data.createDate))}
                      </strong>
                    </li>
                    <li className="list-group-item px-0 border-top-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">تاريخ الفتح :</strong>
                      <strong>
                        {new Intl.DateTimeFormat("ar-EG", {
                          dateStyle: "full",
                        }).format(new Date(data.openDate))}
                      </strong>
                    </li>
                    <li className="list-group-item px-0 border-top-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">
                        تاريخ اخر التحديث :
                      </strong>
                      <strong>
                        {new Intl.DateTimeFormat("ar-EG", {
                          dateStyle: "full",
                        }).format(new Date(data.lastUpdate))}
                      </strong>
                    </li>
                    <li className="list-group-item px-0 border-top-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">اللغة :</strong>
                      <strong>
                        {data.language === "English" ? "الانجليزية" : "العربية"}
                      </strong>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">
                        المستوى الدراسي :
                      </strong>
                      <strong>{data.eduLevel}</strong>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">الحالة :</strong>
                      <strong>{data.active ? "Active" : "Inactive"}</strong>
                    </li>
                    <li className="list-group-item px-0 d-flex justify-content-between">
                      <strong className="mb-0 text-muted">السعر :</strong>
                      <strong>{data.price} جـ.م</strong>
                    </li>
                  </ul>
                  <Link
                    to={`/جميع-الدورات/${data.id}`}
                    className="btn btn-primary">
                    عرض صفحة الدورة
                  </Link>
                </div>
              </Card>
            </Col>
          ))
        )}
      </Row>

      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
        <div className="dataTables_info">
          عرض {pageNo * itemsPerPage + 1} الى{" "}
          {filteredCourses.length > (pageNo + 1) * itemsPerPage
            ? (pageNo + 1) * itemsPerPage
            : filteredCourses.length}{" "}
          من {filteredCourses.length} عناصر
        </div>
        <CustomPagination
          pageNo={pageNo}
          setPageNo={setPageNo}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

const ImageWithFallback = ({ src, fallbackSrc, alt, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={() => setImgSrc(fallbackSrc)}
      {...props}
    />
  );
};

export default AllCourses;
