import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import { extractExpDate } from "../../util/extractExpDate";

export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("token");
  navigate("/login");

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        const tokenData = extractExpDate(response.data.token);
        const userData = {
          email: tokenData.email,
          displayName: tokenData.name,
          idToken: response.data.token,
          registered: true,
          expiresIn: tokenData.exp,
          expireDate: new Date(tokenData.exp * 1000).toISOString(),
        };
        const timer = tokenData.exp * 1000 - Date.now();

        saveTokenInLocalStorage(userData.idToken);
        runLogoutTimer(dispatch, timer, navigate);
        dispatch(loginConfirmedAction(userData));
        navigate("/لوحة-التحكم");
      })
      .catch((error) => {
        console.log(error.response);
        const errorMessage = formatError(error.response);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
