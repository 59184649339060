import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../../layouts/PageTitle";
import Select from "react-select";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Row } from "react-bootstrap";
import { QUESTIONSTYPE } from "../../../../../../util/Selects";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../../../../App";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { UploadToS3 } from "../../../../../../util/UploadToS3";
import {
  EditExamQuestionMCQ,
  EditExamQuestionText,
} from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamQuestions/EditExamQuestion";
import { GetSingleExamQuestion } from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamQuestions/GetSingleExamQuestion";
import isEqual from "lodash.isequal";

const validationSchema = Yup.object().shape({
  questionType: Yup.string()
    .required("نوع السؤال مطلوب")
    .oneOf(["TEXT_BASED", "MCQ"], "نوع السؤال غير صالح"),
  qNo: Yup.number().typeError("رقم السؤال يجب أن يكون رقماً").notRequired(),
  qText: Yup.string()
    .required("نص السؤال مطلوب")
    .min(2, "نص السؤال يجب أن يحتوي على حرفين على الأقل"),
  score: Yup.number()
    .typeError("الدرجة يجب أن تكون رقماً")
    .required("الدرجة مطلوبة"),
  modelAnswer: Yup.string().when("questionType", {
    is: (questionType) => questionType === "TEXT_BASED",
    then: (schema) => schema.required("الإجابة النموذجية مطلوبة"),
    otherwise: (schema) => schema.notRequired(),
  }),
  similarity: Yup.number().when("questionType", {
    is: (questionType) => questionType === "TEXT_BASED",
    then: (schema) =>
      schema
        .typeError("نسبة المطابقة يجب أن تكون رقماً")
        .max(100, "نسبة المطابقة لا يمكن أن تتجاوز 100")
        .required("نسبة المطابقة مطلوبة"),
    otherwise: (schema) => schema.notRequired(),
  }),
  choices: Yup.array().when("questionType", {
    is: (questionType) => questionType === "MCQ",
    then: (schema) =>
      schema
        .of(
          Yup.object().shape({
            cText: Yup.string().required("نص الخيار مطلوب"), // Validation for text to not be empty
            correct: Yup.boolean(),
          })
        )
        .min(2, "يجب إضافة خيارين على الأقل")
        .required("الخيارات مطلوبة")
        .test("one-correct", "يجب تحديد خيار واحد على الأقل كصحيح", (choices) =>
          choices.some((choice) => choice.correct)
        ),
    otherwise: (schema) => schema.notRequired(),
  }),

  image: Yup.mixed().notRequired(),
});

const customStyles = (error, touched) => ({
  control: (provided) => ({
    ...provided,
    borderColor:
      touched && error
        ? "red"
        : touched && !error
        ? "#7ed321"
        : provided.borderColor,
    "&:hover": {
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
    },
    boxShadow:
      touched && error
        ? "0 0 0 1px red"
        : touched
        ? "0 0 0 1px #7ed321"
        : "none",
  }),
});
function EditExamQuestion() {
  const { id, examId, questionId } = useParams();

  const navigate = useNavigate();

  const {
    data: SingleQuestion,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryFn: () => GetSingleExamQuestion(id, examId, questionId),
    queryKey: ["SingleQuestion", questionId],
  });

  const { mutate: editQuestionMCQ } = useMutation({
    mutationFn: (data) => EditExamQuestionMCQ(data, id, examId, questionId),
    onSuccess: () => {
      queryClient.invalidateQueries([
        "ExamOverView",
        "ExamQuestions",
        "SingleQuestion",
        questionId,
      ]);
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم تعديل السؤال بنجاح",
      });
      navigate(`/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });
  const { mutate: editQuestionText } = useMutation({
    queryKey: ["addQuestion"],
    mutationFn: (data) => EditExamQuestionText(data, id, examId, questionId),
    onSuccess: () => {
      queryClient.invalidateQueries(["SingleQuestion", id, examId, questionId]);
      queryClient.invalidateQueries(["ExamQuestions", id, examId]);
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم تعديل السؤال بنجاح",
      });
      navigate(`/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  const initialValues = {
    questionType: SingleQuestion.questionType || "",
    qNo: SingleQuestion.questionNo || "",
    qText: SingleQuestion.questionText || "",
    score: SingleQuestion.score || "",
    modelAnswer: SingleQuestion.modelAnswer || "",
    similarity: SingleQuestion.similarity || "",
    choices:
      SingleQuestion.choices.map((choice, index) => ({
        cText: choice.ctext,
        correct: choice.correct,
        cNo: index + 1,
      })) || [],
    image: SingleQuestion?.image || null,
  };

  return (
    <>
      <PageTitle
        title={"جميع الاسئلة"}
        activeMenu={`تعديل سؤال رقم #${examId}`}
        motherMenu={"الدورات"}
        userId={id}
        secondId={examId}
        secondMenu={"الامتحانات"}
        thirdMenu={"تعديل سؤال"}
        thirdId={questionId}
        goBack
        goTo={`/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{"تفاصيل سؤال رقم #" + questionId}</h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                const initialChoices = initialValues.choices.map((choice) => ({
                  cText: choice.cText,
                  correct: choice.correct,
                  cNo: choice.cNo,
                }));

                const currentChoices = values.choices.map((choice) => ({
                  cText: choice.cText,
                  correct: choice.correct,
                  cNo: choice.cNo,
                }));
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (key === "choices") {
                    if (!isEqual(initialChoices, currentChoices)) {
                      acc[key] = values[key]; // Include choices only if they have changed
                    }
                  } else if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});

                if (Object.keys(changedValues).length === 0) {
                  // If no fields were changed, do not submit and possibly alert the user
                  Swal.fire({
                    icon: "info",
                    title: "لا توجد تغييرات",
                    text: "لا يوجد تغيرات تمت علي المدخلات",
                  });
                  actions.setSubmitting(false);
                  return; // Stop the onSubmit processing here
                }

                const imageChanged =
                  values.image && values.image !== initialValues.image;

                // Create the data object to be submitted
                const data = {
                  ...changedValues,
                };

                // Add the new image name to the data object only if the image has changed
                if (imageChanged) {
                  data.image = uuidv4() + values.image.name;
                }

                if (values.questionType === "TEXT_BASED") {
                  editQuestionText(data, {
                    onSuccess: (data) => {
                      actions.resetForm();
                      actions.setSubmitting(false);
                      if (changedValues.image) {
                        UploadToS3(
                          data.image,
                          changedValues.image,
                          "Teacher/CouresImg/Exams/",
                          false
                        );
                      }
                    },
                    onError: () => {
                      actions.setSubmitting(false);
                      Swal.fire({
                        icon: "error",
                        title: "خطأ...",
                        text: "حدث خطأ!",
                      });
                    },
                  });
                } else {
                  editQuestionMCQ(data, {
                    onSuccess: (data) => {
                      actions.resetForm();
                      actions.setSubmitting(false);
                      if (changedValues.image) {
                        UploadToS3(
                          data.image,
                          changedValues.image,
                          "Teacher/CourseFiles/Exams/",
                          false
                        );
                      }
                    },
                    onError: () => {
                      actions.setSubmitting(false);
                      Swal.fire({
                        icon: "error",
                        title: "خطأ...",
                        text: "حدث خطأ!",
                      });
                    },
                  });
                }
                actions.setSubmitting(false);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                setFieldTouched,
                resetForm,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="questionType">
                            نوع السؤال
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isDisabled
                            name="questionType"
                            id="questionType"
                            placeholder="اختر نوع السؤال"
                            isSearchable={false}
                            options={QUESTIONSTYPE}
                            styles={customStyles(
                              errors.eduLevelId,
                              touched.eduLevelId
                            )}
                            className={`custom-react-select ${
                              touched.questionType
                                ? errors.questionType
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={QUESTIONSTYPE.find(
                              (option) => option.value === values.questionType
                            )}
                          />
                          {touched.questionType && errors.questionType && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.questionType}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="qNo">
                            رقم السؤال
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="qNo"
                            placeholder="ادخل رقم السؤال"
                            type="text"
                            name="qNo"
                            className={`form-control ${
                              touched.qNo
                                ? errors.qNo
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.qNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.qNo && errors.qNo && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.qNo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="qText">
                            السؤال
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="qText"
                            placeholder="ادخل السؤال"
                            type="text"
                            name="qText"
                            className={`form-control ${
                              touched.qText
                                ? errors.qText
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.qText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.qText && errors.qText && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.qText}
                            </div>
                          )}
                        </div>
                      </div>

                      {values.questionType === "TEXT_BASED" && (
                        <>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="modelAnswer">
                                الاجابة الصحيحة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="modelAnswer"
                                placeholder="ادخل الاجابة الصحيحة"
                                type="text"
                                name="modelAnswer"
                                className={`form-control ${
                                  touched.modelAnswer
                                    ? errors.modelAnswer
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.modelAnswer}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.modelAnswer && errors.modelAnswer && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.modelAnswer}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="score">
                                الدرجة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="score"
                                placeholder="ادخل الدرجة"
                                type="text"
                                name="score"
                                className={`form-control ${
                                  touched.score
                                    ? errors.score
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.score}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.score && errors.score && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.score}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="similarity">
                                نسبة المطابقة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="similarity"
                                placeholder="ادخل نسبة المطابقة %"
                                type="text"
                                name="similarity"
                                className={`form-control ${
                                  touched.similarity
                                    ? errors.similarity
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.similarity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.similarity && errors.similarity && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.similarity}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {values.questionType === "MCQ" && (
                        <>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="score">
                                الدرجة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="score"
                                placeholder="ادخل الدرجة"
                                type="text"
                                name="score"
                                className={`form-control ${
                                  touched.score
                                    ? errors.score
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.score}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.score && errors.score && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.score}
                                </div>
                              )}
                            </div>
                          </div>

                          <FieldArray name="choices">
                            {({ push, remove }) => (
                              <>
                                <div className="col-sm-12">
                                  <label className="form-label mb-4">
                                    الاختيارت والاختيار الصحيح
                                    <span className="text-danger">*</span>
                                  </label>
                                  {values.choices.map((choice, index) => (
                                    <div
                                      key={index}
                                      className="form-group">
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="radio"
                                          name={`choices.${index}.correct`}
                                          className="form-check-input me-2"
                                          checked={choice.correct}
                                          onChange={() => {
                                            const newChoices =
                                              values.choices.map((ch, i) => ({
                                                ...ch,
                                                correct: i === index,
                                              }));
                                            setFieldValue(
                                              "choices",
                                              newChoices
                                            );
                                          }}
                                        />

                                        <input
                                          id={`choices.${index}.ctext`}
                                          placeholder={`نص اجابة ${index + 1}`}
                                          type="text"
                                          name={`choices.${index}.ctext`}
                                          className={`form-control ${
                                            touched.choices &&
                                            touched.choices[index] &&
                                            touched.choices[index].ctext
                                              ? errors.choices &&
                                                errors.choices[index] &&
                                                errors.choices[index].ctext
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                          value={choice.cText}
                                          onChange={(e) => {
                                            const newChoices =
                                              values.choices.slice();
                                            newChoices[index].cText =
                                              e.target.value;
                                            newChoices[index].cNo = index + 1; // Ensure cNo is updated
                                            setFieldValue(
                                              "choices",
                                              newChoices
                                            );
                                          }}
                                          onBlur={handleBlur}
                                        />

                                        <Button
                                          variant="danger"
                                          onClick={() => remove(index)}>
                                          <i className="fa fa-trash" />
                                        </Button>
                                      </div>

                                      {touched.choices &&
                                        touched.choices[index] &&
                                        touched.choices[index].ctext &&
                                        errors.choices &&
                                        errors.choices[index] &&
                                        errors.choices[index].ctext && (
                                          <div className="invalid-feedback animated fadeInUp d-block">
                                            {errors.choices[index].ctext}
                                          </div>
                                        )}
                                      {errors.choices &&
                                        typeof errors.choices === "string" && (
                                          <div className="invalid-feedback animated fadeInUp d-block">
                                            {errors.choices}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </div>
                                <span>
                                  <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() =>
                                      push({
                                        ctext: "",
                                        correct: false,
                                        cNo: values.choices.length + 1,
                                      })
                                    }>
                                    اضافة اختيار
                                  </Button>
                                </span>
                              </>
                            )}
                          </FieldArray>
                        </>
                      )}

                      <div className="col-sm-6 mt-4">
                        <div className="form-group">
                          <label
                            className="form-label mb-4"
                            htmlFor="image">
                            الصورة
                          </label>
                          <input
                            type="file"
                            name="image"
                            onChange={(event) =>
                              setFieldValue(
                                "image",
                                event.currentTarget.files[0]
                              )
                            }
                            className="form-control"
                          />
                          {touched.image && errors.image && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Button
                          type="submit"
                          className="btn btn-primary me-1">
                          اضافة
                        </Button>
                        <Button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          الغاء
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditExamQuestion;
