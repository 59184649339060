import { Link } from "react-router-dom";
import { IMAGES } from "../../../constant/theme";
import { Row } from "react-bootstrap";
import { IoIosStar } from "react-icons/io";
import { COURSEIMG } from "../../../../util/UploadToS3";

function OverView({
  CourseOverView,
  CourseOverViewLoading,
  CourseOverViewError,
}) {
  if (CourseOverViewLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );

  if (CourseOverViewError)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <span className="text-primary">حدث خطأ...</span>
        </div>
      </Row>
    );
  return (
    <div className="row mt-5">
      <div className="col-xl-4 col-xxl-4 col-lg-4">
        <div className="row ">
          <div className="col-lg-12 ">
            <div className="card ">
              <img
                className="img-fluid"
                src={`${COURSEIMG}${
                  CourseOverView.image
                }?${new Date().getTime()}`}
                onError={(e) => (e.target.src = IMAGES.courseBroken)}
                alt="CourseImg"
              />
              <div className="card-body ">
                <h3 className="mb-0">{CourseOverView.title}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-12 ">
            <div className="card overflow-hidden ">
              <div className="card-header">
                <h2 className="card-title">عن الدورة</h2>
              </div>
              <div className="card-body pb-0 ">
                <p>
                  {String(CourseOverView.subtitle).charAt(0).toUpperCase() +
                    String(CourseOverView.subtitle).slice(1)}
                </p>
                <ul className="list-group list-group-flush">
                  {/* {detailblog.map((item, ind) => (
                    <li
                      className="list-group-item d-flex px-0 justify-content-between"
                      key={ind}>
                      <strong>{item.title}</strong>
                      <span className="mb-0">{item.subtitle}</span>
                    </li>
                  ))} */}
                  <li className="list-group-item d-flex px-0 py-4 justify-content-between border-light">
                    <strong>تاريخ الانشاء</strong>
                    <span className="mb-0">
                      {new Intl.DateTimeFormat("ar-EG", {
                        dateStyle: "full",
                      }).format(new Date(CourseOverView.createDate))}
                    </span>
                  </li>
                  <li className="list-group-item d-flex px-0 py-4 justify-content-between border-light">
                    <strong>السعر</strong>
                    <span className="mb-0">{CourseOverView.price} جـ.م</span>
                  </li>
                  <li className="list-group-item d-flex px-0 py-4 justify-content-between border-light">
                    <strong>متوسط التقييمات</strong>
                    <span className="d-flex align-items-center gap-1">
                      <span> {CourseOverView.avgRate.toFixed(2)}</span>
                      <IoIosStar
                        size={22}
                        fill="#FFD600"
                      />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-footer pt-0 pb-0 text-center">
                <div className="row">
                  <div className="col-4 pt-3 pb-3 border-end">
                    <h3 className="mb-1 text-primary">
                      {CourseOverView.totalEnrolls}
                    </h3>
                    <strong>جميع التسجيلات</strong>
                  </div>
                  <div className="col-4 pt-3 pb-3 border-end">
                    <h3 className="mb-1 text-primary">
                      {CourseOverView.totalRates}
                    </h3>
                    <strong>جميع التقييمات</strong>
                  </div>
                  <div className="col-4 pt-3 pb-3">
                    <h3 className="mb-1 text-primary">
                      {CourseOverView.monthly ? "شهريا" : "مرة واحدة"}
                    </h3>
                    <strong>طريقة الدفع</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-8 col-xxl-8 col-lg-8 ">
        <div className="card ">
          <div className="card-body">
            <h2 className="text-primary border-bottom pb-3  border-light">
              نظرة عامة على الدورة
            </h2>
            <h3 className="text-primary">وصف الدورة</h3>

            <p>{CourseOverView.description}</p>

            <h3 className="text-primary">المستوي الدراسي</h3>
            <div className="profile-skills pt-2 border-bottom-1 pb-2">
              <Link
                to={"#"}
                className="btn btn-outline-dark btn-rounded px-4 my-3 my-sm-0 me-3 m-b-10">
                {CourseOverView.eduLevel.label}
              </Link>
            </div>

            <div className="profile-personal-info mt-4">
              <h3 className="text-primary">معلومات الدورة</h3>

              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <h5 className="f-w-500">
                    الرقم التعريفي
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                  <span>#{CourseOverView.id}</span>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <h5 className="f-w-500">
                    العنوان
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                  <span>{CourseOverView.title}</span>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <h5 className="f-w-500">
                    العنوان الفرعي
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                  <span>{CourseOverView.subtitle}</span>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <h5 className="f-w-500">
                    تاريخ الافتتاح
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                  <span>
                    {new Intl.DateTimeFormat("ar-EG", {
                      dateStyle: "full",
                    }).format(new Date(CourseOverView.openDate))}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <h5 className="f-w-500">
                    تاريخ اخر تحديث
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                  <span>
                    {new Intl.DateTimeFormat("ar-EG", {
                      dateStyle: "full",
                    }).format(new Date(CourseOverView.lastUpdate))}
                  </span>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                  <h5 className="f-w-500">
                    حاله الدورة
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                  <span>{CourseOverView.active ? "Active" : "Not Active"}</span>
                </div>
              </div>
            </div>
            <ul className="list-group mb-3 list-group-flush">
              <h3 className="text-primary">متطلبات الدورة</h3>
              {CourseOverView.requirements.map((requirement, index) => (
                <li
                  key={index}
                  className="list-group-item border-0 px-0 ">
                  {requirement}
                </li>
              ))}
            </ul>
            <ul className="list-group mb-3 list-group-flush">
              <h3 className="text-primary">المستفاد من الدورة</h3>
              {CourseOverView.outcomes.map((outcome, index) => (
                <li
                  key={index}
                  className="list-group-item border-0 px-0 ">
                  {outcome}
                </li>
              ))}
            </ul>
            <div className="profile-lang pt-5 border-bottom-1 pb-5">
              <h4 className="text-primary mb-4">اللغة</h4>
              <Link
                to={"#"}
                className="badge badge-primary light me-1">
                <i className="flag-icon flag-icon-us" />{" "}
                {CourseOverView.language}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverView;
