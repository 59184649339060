import React, { useState } from "react";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import { Loader, Row } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetMyProfile } from "../../../API/MyProfile/GetMyProfile";
import { UpdateMyProfile } from "../../../API/MyProfile/UpdateMyProfile";
import { queryClient } from "../../../App";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { PROFILEIMG, UploadToS3 } from "../../../util/UploadToS3";
import { IMAGES } from "../../constant/theme";
import { COUNTRIES } from "../../../util/Countries";
import { DebounceFun } from "../../../util/DebounceFun";
import { CheckEmailPhoneTeacher } from "../../../API/CheckEmailPhoneTeacher";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
const debouncedCheckEmail = DebounceFun(
  (email) => CheckEmailPhoneTeacher(email, ""),
  500
);
const debouncedCheckPhone = DebounceFun(
  (phone) => CheckEmailPhoneTeacher("", phone),
  500
);

const EditMyProfile = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    data: MyProfile,
    isLoading: MyProfileLoading,
    isError: MyProfileError,
    error,
  } = useQuery({ queryKey: ["MyProfile"], queryFn: () => GetMyProfile() });

  const { mutate } = useMutation({
    mutationFn: (data) => UpdateMyProfile(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["MyProfile"] });
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم تحديث الملف الشخصي بنجاح",
      });
      navigate("/الملف-الشخصي-الخاص بي");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  if (
    MyProfileError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  if (MyProfileLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  const initialValues = {
    firstName: MyProfile.firstName || "",
    lastName: MyProfile.lastName || "",
    password: "",
    confirmPassword: "",
    email: MyProfile.email || "",
    phone: MyProfile.phone || "",
    language: MyProfile.language || "",
    country: MyProfile.country || "",
    experience: MyProfile.experience || "",
    overview: MyProfile.overview || "",
    jobTitle: MyProfile.jobTitle || "",
    image: MyProfile.image || null,
  };

  const languages = [
    { value: "Arabic", label: "العربية" },
    { value: "English", label: "الانجليزية" },
  ];

  const loginSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "يجب أن يتكون الاسم الأول من 3 أحرف على الأقل")
      .max(50, "يجب ألا يزيد الاسم الأول عن 50 حرفًا")
      .matches(
        /^[a-zA-Z\s-_]*$/,
        "يجب أن يحتوي الاسم الأول على أحرف فقط، ومسافات، وشرطات سفلية، وشرطات"
      ),
    lastName: Yup.string()
      .min(3, "يجب أن يتكون الاسم الأخير من 3 أحرف على الأقل")
      .max(50, "يجب ألا يزيد الاسم الأخير عن 50 حرفًا")
      .matches(
        /^[a-zA-Z\s-_]*$/,
        "يجب أن يحتوي الاسم الأخير على أحرف فقط، ومسافات، وشرطات سفلية، وشرطات"
      ),
    password: Yup.string()
      .min(5, "يجب أن تكون كلمة المرور مكونة من 5 أحرف على الأقل")
      .max(50, "يجب أن تكون كلمة المرور مكونة من 50 حرفًا على الأكثر")
      .matches(
        passwordRegex,
        "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل، وحرف صغير واحد، ورقم واحد، وحرف خاص واحد"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "يجب أن تتطابق كلمتا المرور"
    ),
    email: Yup.string()
      .email("البريد الإلكتروني غير صالح")
      .required("يرجى إدخال البريد الإلكتروني")
      .test(
        "is-email-unique",
        "البريد الإلكتروني قيد الاستخدام بالفعل",
        async function (value) {
          if (!value || value === initialValues.email) return true;
          try {
            const data = await debouncedCheckEmail(value);
            if (data.emailExists) {
              return this.createError({
                path: this.path,
                message: "البريد الإلكتروني قيد الاستخدام بالفعل",
              });
            }
            return true;
          } catch (error) {
            console.error("خطأ في التحقق من البريد الإلكتروني:", error);
            return this.createError({
              path: this.path,
              message: "فشل التحقق من صحة البريد الإلكتروني",
            });
          }
        }
      ),
    phone: Yup.number()
      .required("يرجى إدخال رقم الهاتف")
      .typeError("يجب أن يكون رقم الهاتف رقماً")
      .positive("يجب أن يكون رقم الهاتف رقماً موجباً")
      .test(
        "is-phone-unique",
        "رقم الهاتف قيد الاستخدام بالفعل",
        async function (value) {
          if (!value || value === initialValues.phone) return true;
          try {
            const data = await debouncedCheckPhone(value);
            if (data.phoneExists) {
              return this.createError({
                path: this.path,
                message: "رقم الهاتف قيد الاستخدام بالفعل",
              });
            }
            return true;
          } catch (error) {
            console.error("خطأ في التحقق من رقم الهاتف:", error);
            return this.createError({
              path: this.path,
              message: "فشل التحقق من صحة رقم الهاتف",
            });
          }
        }
      )
      .test(
        "isAtLeastTwoDigits",
        "يجب أن يحتوي رقم الهاتف على 11 رقمًا على الأقل",
        (number) => number && number.toString().length > 9
      )
      .test(
        "isAtLeastTwoDigits",
        "يجب أن يحتوي رقم الهاتف على 11 رقمًا كحد أقصى",
        (number) => number && number.toString().length <= 11
      ),
    experience: Yup.number()
      .required("يرجى إدخال سنوات الخبرة")
      .typeError("يجب أن تكون سنوات الخبرة رقماً")
      .positive("يجب أن تكون سنوات الخبرة رقماً موجباً")
      .test(
        "isAtLeastTwoDigits",
        "يجب أن تكون سنوات الخبرة رقمًا واحدًا على الأقل",
        (number) => number && number.toString().length >= 1
      ),
    language: Yup.string().required("يرجى اختيار لغة"),
    overview: Yup.string().required("يرجى إدخال نظرة عامة").min(10),
    jobTitle: Yup.string().required("يرجى إدخال عنوان الوظيفة"),
    country: Yup.string().notOneOf([""], "يرجى اختيار دولة"),
  });

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);
    setImagePreview(URL.createObjectURL(file));
  };

  const customStyles = (error, touched) => ({
    control: (provided) => ({
      ...provided,
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
      "&:hover": {
        borderColor:
          touched && error
            ? "red"
            : touched && !error
            ? "#7ed321"
            : provided.borderColor,
      },
      boxShadow:
        touched && error
          ? "0 0 0 1px red"
          : touched
          ? "0 0 0 1px #7ed321"
          : "none",
    }),
  });

  return (
    <>
      <PageTitle
        title={"الملف الشخصي"}
        activeMenu={"تعديل الملف الشخصي"}
        motherMenu={"الملف الشخصي"}
        goBack
        noNavigat
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">معلوماتي الشخصية</h5>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                actions.setSubmitting(true);
                mutate(changedValues, {
                  onSuccess: (data) => {
                    actions.setSubmitting(false);
                    if (changedValues.image) {
                      // Check if image has changed
                      UploadToS3(
                        data.image, // Assuming `data.image` is the path where the image should be saved on S3
                        changedValues.image,
                        "Teacher/ProfileImg/",
                        true
                      );
                    }
                  },

                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="p-4">
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={
                                imagePreview
                                  ? imagePreview
                                  : PROFILEIMG + MyProfile?.image
                              }
                              alt="ProfileImg"
                              onError={(e) => {
                                e.target.src = IMAGES.Avatpng1;
                              }}
                            />
                            <div
                              className="upload-link"
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update">
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="update-flie"
                                onChange={(event) =>
                                  handleImageChange(event, setFieldValue)
                                }
                                onBlur={handleBlur}
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          {errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                          <div className="author-info">
                            <h6 className="title">
                              {MyProfile?.firstName + " " + MyProfile?.lastName}
                            </h6>
                            <span>#{MyProfile.id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="firstName">
                            الاسم الاول
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="firstName"
                            placeholder="ادخل الاسم الاول"
                            type="text"
                            name="firstName"
                            className={`form-control ${
                              touched.firstName
                                ? errors.firstName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.firstName && errors.firstName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lastName">
                            الاسم الاخير
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lastName"
                            placeholder="ادخل الاسم الاخير"
                            type="text"
                            name="lastName"
                            className={`form-control ${
                              touched.lastName
                                ? errors.lastName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lastName && errors.lastName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Email">
                            البريد الالكتروني
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Email"
                            placeholder="ادخل البريد الالكتروني"
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="phone">
                            رقم الهاتف
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="phone"
                            placeholder="ادخل رقم الهاتف"
                            type="text"
                            name="phone"
                            className={`form-control ${
                              touched.phone
                                ? errors.phone
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phone && errors.phone && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.password
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="Password">
                            الرقم السري
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="Password"
                              placeholder="ادخل الرقم السري"
                              type={`${showPassword ? "text" : "password"}`}
                              name="password"
                              className={`form-control ${
                                touched.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.password && errors.password && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.confirmPassword
                              ? errors.confirmPassword
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="confirmPassword">
                            تأكيد الرقم السري
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="confirmPassword"
                              placeholder="ادخل تأكيد الرقم السري"
                              type={`${showPassword ? "text" : "password"}`}
                              name="confirmPassword"
                              className={`form-control ${
                                touched.confirmPassword
                                  ? errors.confirmPassword
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.language
                              ? errors.language
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label className="form-label">
                            اللغة
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="language"
                            id="language"
                            placeholder="ادخل اللغة"
                            isSearchable={false}
                            options={languages}
                            styles={customStyles(
                              errors.language,
                              touched.language
                            )}
                            className={`custom-react-select ${
                              touched.language
                                ? errors.language
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={languages.find(
                              (option) => option.value === values.language
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "language",
                                option ? option.value : ""
                              );
                              setFieldTouched("language", true, false); // mark as touched but do not validate yet
                              validateField("language"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("language", true)}
                          />
                          {touched.language && errors.language && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="country"
                            className="form-label">
                            الدولة
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="country"
                            id="country"
                            placeholder="اختر الدولة"
                            isSearchable={true}
                            options={COUNTRIES}
                            styles={customStyles(
                              errors.country,
                              touched.country
                            )}
                            className={`custom-react-select ${
                              touched.country
                                ? errors.country
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={COUNTRIES.find(
                              (option) => option.value === values.country
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "country",
                                option ? option.value : ""
                              );
                              setFieldTouched("country", true, false); // mark as touched but do not validate yet
                              validateField("country"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("country", true)}
                          />
                          {touched.country && errors.country && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.country}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="jobTitle">
                            المسمى الوظيفي
                          </label>
                          <input
                            id="jobTitle"
                            placeholder="ادخل المسمى الوظيفي"
                            type="text"
                            name="jobTitle"
                            className={`form-control ${
                              touched.jobTitle
                                ? errors.jobTitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.jobTitle && errors.jobTitle && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.jobTitle}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="experience">
                            سنوات الخبرة
                          </label>
                          <input
                            id="experience"
                            placeholder="ادخل سنوات الخبرة"
                            type="text"
                            name="experience"
                            className={`form-control ${
                              touched.experience
                                ? errors.experience
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.experience}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.experience && errors.experience && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.experience}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="overview">
                            الوصف
                          </label>
                          <input
                            id="overview"
                            placeholder="ادخل الوصف"
                            type="text"
                            name="overview"
                            className={`form-control ${
                              touched.overview
                                ? errors.overview
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.overview}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.overview && errors.overview && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.overview}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "اضافة"}
                        </button>
                        <button
                          onClick={() => navigate("/جميع-الطلاب")}
                          type="button"
                          className="btn btn-danger light">
                          الغاء
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMyProfile;
