import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../../layouts/PageTitle";
import Select from "react-select";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { QUESTIONSTYPE } from "../../../../../../util/Selects";
import { useMutation } from "@tanstack/react-query";
import {
  AddExamQuestionMCQ,
  AddExamQuestionText,
} from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamQuestions/AddExamQuestion";
import { queryClient } from "../../../../../../App";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { UploadToS3 } from "../../../../../../util/UploadToS3";

const validationSchema = Yup.object().shape({
  questionType: Yup.string()
    .required("نوع السؤال مطلوب")
    .oneOf(["TEXT_BASED", "MCQ"], "نوع السؤال غير صالح"),
  qNo: Yup.number()
    .typeError("رقم السؤال يجب أن يكون رقماً")
    .min(0, "رقم السؤال لا يمكن أن يكون سالباً")
    .notRequired(),
  qText: Yup.string()
    .required("نص السؤال مطلوب")
    .min(2, "نص السؤال يجب أن يحتوي على حرفين على الأقل")
    .matches(/^(?!\d+$).*/, "نص السؤال لا يمكن أن يتكون من أرقام فقط"),
  score: Yup.number()
    .typeError("الدرجة يجب أن تكون رقماً")
    .required("الدرجة مطلوبة")
    .min(0, "الدرجة لا يمكن أن تكون سلبية"),
  modelAnswer: Yup.string().when("questionType", {
    is: (questionType) => questionType === "TEXT_BASED",
    then: (schema) => schema.required("الإجابة النموذجية مطلوبة"),
    otherwise: (schema) => schema.notRequired(),
  }),
  similarity: Yup.number().when("questionType", {
    is: (questionType) => questionType === "TEXT_BASED",
    then: (schema) =>
      schema
        .typeError("التشابه يجب أن يكون رقماً")
        .max(100, "التشابه لا يمكن أن يتجاوز 100")
        .required("التشابه مطلوب")
        .min(0, "التشابه لا يمكن أن يكون سلبياً"),
    otherwise: (schema) => schema.notRequired(),
  }),
  choices: Yup.array().when("questionType", {
    is: (questionType) => questionType === "MCQ",
    then: (schema) =>
      schema
        .of(
          Yup.object().shape({
            cText: Yup.string().required("نص الخيار مطلوب"), // Validation for text to not be empty
            correct: Yup.boolean(),
          })
        )
        .min(2, "يجب إضافة خيارين على الأقل")
        .required("الخيارات مطلوبة")
        .test("one-correct", "يجب تحديد خيار واحد على الأقل كصحيح", (choices) =>
          choices.some((choice) => choice.correct)
        ),
    otherwise: (schema) => schema.notRequired(),
  }),

  image: Yup.mixed()
    .notRequired()
    .test(
      "fileFormat",
      "تنسيق الملف غير مدعوم",
      (value) =>
        !value ||
        (value && ["image/jpeg", "image/png", "image/gif"].includes(value.type))
    ),
});

const customStyles = (error, touched) => ({
  control: (provided) => ({
    ...provided,
    borderColor:
      touched && error
        ? "red"
        : touched && !error
        ? "#7ed321"
        : provided.borderColor,
    "&:hover": {
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
    },
    boxShadow:
      touched && error
        ? "0 0 0 1px red"
        : touched
        ? "0 0 0 1px #7ed321"
        : "none",
  }),
});

function AddExamQuestion() {
  const { id, examId } = useParams();
  const navigate = useNavigate();
  const { mutate: addQuestionMCQ } = useMutation({
    mutationFn: (data) => AddExamQuestionMCQ(data, id, examId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ExamOverView", "ExamQuestions"],
      });
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم اضافة السؤال بنجاح",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });
  const { mutate: addQuestionText } = useMutation({
    queryKey: ["addQuestion"],
    mutationFn: (data) => AddExamQuestionText(data, id, examId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ExamOverView", "ExamQuestions"],
      });
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم اضافة السؤال بنجاح",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  return (
    <>
      <PageTitle
        title={"جميع الاسئلة"}
        activeMenu={"جميع الدورات"}
        motherMenu={"الدورات"}
        userId={id}
        secondId={examId}
        secondMenu={"الامتحانات"}
        thirdMenu={"اضافة سؤال"}
        goBack
        goTo={`/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">تفصيل السؤال</h4>
            </div>
            <Formik
              initialValues={{
                questionType: "TEXT_BASED",
                qNo: "",
                qText: "",
                score: "",
                modelAnswer: "",
                similarity: "",
                choices: [],
                image: null,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                const { image, ...restValues } = values;
                let data = { ...restValues };

                if (image) {
                  const imageName = uuidv4() + image.name;
                  data = { image: imageName, ...restValues };
                }

                if (values.questionType === "TEXT_BASED") {
                  addQuestionText(data, {
                    onSuccess: () => {
                      actions.resetForm();
                      actions.setSubmitting(false);
                      if (image) {
                        UploadToS3(
                          image,
                          data.image,
                          "Teacher/CouresImg/Exams/",
                          false
                        );
                      }
                      navigate(
                        `/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة`
                      );
                    },
                    onError: () => {
                      actions.setSubmitting(false);
                      Swal.fire({
                        icon: "error",
                        title: "خطأ...",
                        text: "حدث خطأ!",
                      });
                    },
                  });
                } else {
                  addQuestionMCQ(data, {
                    onSuccess: () => {
                      actions.resetForm();
                      actions.setSubmitting(false);
                      if (image) {
                        UploadToS3(
                          image,
                          data.image,
                          "Teacher/CourseFiles/Exams/",
                          false
                        );
                      }
                      navigate(
                        `/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة`
                      );
                    },
                    onError: () => {
                      actions.setSubmitting(false);
                      Swal.fire({
                        icon: "error",
                        title: "خطأ...",
                        text: "حدث خطأ!",
                      });
                    },
                  });
                }
                actions.setSubmitting(false);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                setFieldTouched,
                resetForm,
                validateField,
              }) => (
                <div className="card-body">
                  {console.log(errors)}
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="questionType">
                            نوع السؤال
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="questionType"
                            id="questionType"
                            placeholder="اختر نوع السؤال"
                            isSearchable={false}
                            options={QUESTIONSTYPE}
                            styles={customStyles(
                              errors.eduLevelId,
                              touched.eduLevelId
                            )}
                            className={`custom-react-select ${
                              touched.questionType
                                ? errors.questionType
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={QUESTIONSTYPE.find(
                              (option) => option.value === values.questionType
                            )}
                            onChange={(option) => {
                              // Reset the form with the new question type and default values for all other fields
                              resetForm({
                                values: {
                                  questionType: option.value,
                                  qNo: "",
                                  qText: "",
                                  score: "",
                                  modelAnswer: "",
                                  similarity: "",
                                  choices: [],
                                  image: null,
                                },
                              });
                              setFieldTouched("questionType", true, false); // mark as touched but do not validate yet
                              validateField("questionType"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("questionType", true)}
                          />
                          {touched.questionType && errors.questionType && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.questionType}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="qNo">
                            رقم السؤال
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="qNo"
                            placeholder="ادخل رقم السؤال"
                            type="text"
                            name="qNo"
                            className={`form-control ${
                              touched.qNo
                                ? errors.qNo
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.qNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.qNo && errors.qNo && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.qNo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="qText">
                            السؤال
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="qText"
                            placeholder="ادخل السؤال"
                            type="text"
                            name="qText"
                            className={`form-control ${
                              touched.qText
                                ? errors.qText
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.qText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.qText && errors.qText && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.qText}
                            </div>
                          )}
                        </div>
                      </div>

                      {values.questionType === "TEXT_BASED" && (
                        <>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="modelAnswer">
                                الاجابة الصحيحة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="modelAnswer"
                                placeholder="ادخل الاجابة الصحيحة"
                                type="text"
                                name="modelAnswer"
                                className={`form-control ${
                                  touched.modelAnswer
                                    ? errors.modelAnswer
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.modelAnswer}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.modelAnswer && errors.modelAnswer && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.modelAnswer}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="score">
                                درجة السؤال
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="score"
                                placeholder="ادخل الدرجة"
                                type="text"
                                name="score"
                                className={`form-control ${
                                  touched.score
                                    ? errors.score
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.score}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.score && errors.score && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.score}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="similarity">
                                نسبة المطابقة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="similarity"
                                placeholder="ادخل نسبة المطابقة %"
                                type="text"
                                name="similarity"
                                className={`form-control ${
                                  touched.similarity
                                    ? errors.similarity
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.similarity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.similarity && errors.similarity && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.similarity}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {values.questionType === "MCQ" && (
                        <>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="score">
                                الدرجة
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                id="score"
                                placeholder="ادخل الدرجة"
                                type="text"
                                name="score"
                                className={`form-control ${
                                  touched.score
                                    ? errors.score
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                value={values.score}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {touched.score && errors.score && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.score}
                                </div>
                              )}
                            </div>
                          </div>

                          <FieldArray name="choices">
                            {({ push, remove, form }) => (
                              <>
                                <div className="col-sm-12">
                                  <label className="form-label mb-4">
                                    الاختيارت والاختيار الصحيح
                                    <span className="text-danger">*</span>
                                  </label>
                                  {values.choices.map((choice, index) => (
                                    <div
                                      key={index}
                                      className="form-group">
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="radio"
                                          name={`choices.${index}.correct`}
                                          className="form-check-input me-2"
                                          checked={choice.correct}
                                          onChange={() => {
                                            const newChoices =
                                              values.choices.map((ch, i) => ({
                                                ...ch,
                                                correct: i === index,
                                              }));
                                            setFieldValue(
                                              "choices",
                                              newChoices
                                            );
                                          }}
                                        />

                                        <input
                                          id={`choices.${index}.cText`}
                                          placeholder={`نص اجابة ${index + 1}`}
                                          type="text"
                                          name={`choices.${index}.cText`}
                                          className={`form-control ${
                                            touched.choices &&
                                            touched.choices[index] &&
                                            touched.choices[index].cText
                                              ? errors.choices &&
                                                errors.choices[index] &&
                                                errors.choices[index].cText
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                          value={choice.cText}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />

                                        <Button
                                          variant="danger"
                                          onClick={() => remove(index)}>
                                          <i className="fa fa-trash" />
                                        </Button>
                                      </div>

                                      {touched.choices &&
                                        touched.choices[index] &&
                                        touched.choices[index].text &&
                                        errors.choices &&
                                        errors.choices[index] &&
                                        errors.choices[index].text && (
                                          <div className="invalid-feedback animated fadeInUp d-block">
                                            {errors.choices[index].text}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                  {errors.choices &&
                                    typeof errors.choices === "string" && (
                                      <div className="invalid-feedback animated fadeInUp d-block">
                                        {errors.choices}
                                      </div>
                                    )}
                                </div>
                                <span>
                                  <Button
                                    variant="primary "
                                    type="button"
                                    onClick={() =>
                                      push({
                                        cNo: values.choices.length + 1,
                                        cText: "",
                                        correct: false,
                                      })
                                    }>
                                    اضافة اختيار
                                  </Button>
                                </span>
                              </>
                            )}
                          </FieldArray>
                        </>
                      )}

                      <div className="col-sm-6 mt-4">
                        <div className="form-group">
                          <label
                            className="form-label mb-4"
                            htmlFor="image">
                            الصورة
                          </label>
                          <input
                            type="file"
                            name="image"
                            onChange={(event) =>
                              setFieldValue(
                                "image",
                                event.currentTarget.files[0]
                              )
                            }
                            className="form-control"
                          />
                          {touched.image && errors.image && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Button
                          type="submit"
                          className="btn btn-primary me-1">
                          اضافة
                        </Button>
                        <Button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          الغاء
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddExamQuestion;
