import React from "react";
import {
  Row,
  Col,
  Card,
  ProgressBar,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import jsPDF from "jspdf";
import "jspdf-autotable";
//Import Components
import { BgCard } from "../../elements/CardDesign";
import { useQuery } from "@tanstack/react-query";
import { getDashboardData } from "../../../API/GetDashboardData";
import Swal from "sweetalert2";

// import DualArea from "../charts/Chartjs/dualArea";

//Charts
const DonughtChart = loadable(() =>
  pMinDelay(import("../../elements/dashboard/DonughtChart"), 500)
);
const DualArea = loadable(() =>
  pMinDelay(import("../charts/Chartjs/dualArea"), 500)
);

const Home = () => {
  const {
    isLoading,
    data: dashboard,
    isError,
    error,
  } = useQuery({
    queryKey: ["DashboardData"],
    queryFn: () => getDashboardData(),
  });
  // Generateing report

  const generatePDF = (data) => {
    const doc = new jsPDF();

    // Add a title
    doc.setFontSize(24);
    doc.text("Report", 14, 22);

    // Adding some data directly
    doc.setFontSize(18);
    doc.text("Overall Statistics", 14, 40);
    doc.setFontSize(11);
    doc.text(
      `Total Usage: ${(data.totalUsageStorage / 1024).toFixed(2)} GB`,
      14,
      52
    );
    doc.text(`Total Users: ${data.studentNumbers}`, 14, 62);
    doc.text(`Total Income: $${data.totalIncome}`, 14, 72);
    doc.text(`Total Courses: ${data.totalCourses}`, 14, 82);

    // Generate tables from data
    doc.text("Top Courses Enrollments:", 14, 100);
    doc.autoTable({
      startY: 110,
      theme: "striped",
      head: [["Community", "Enrollments"]],
      body: Object.entries(data.topCourseEnrollment).map(([name, count]) => [
        name,
        count,
      ]),
    });
    doc.text("Top Courses Income:", 14, doc.lastAutoTable.finalY + 20);

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 30,
      theme: "grid",
      head: [["Community", "Income"]],
      body: Object.entries(data.topCourseIncome).map(([name, income]) => [
        name,
        income,
      ]),
    });

    // Save the PDF
    doc.save("report.pdf");
  };
  const bgCarddBlog = isLoading
    ? []
    : [
        {
          title: "جميع الدورات",
          number: dashboard?.courseNumbers,
          icon: <i className="la la-graduation-cap" />,
          color: "primary",
          maxCapacity: 200,
          unit: "دورة",
        },
        {
          title: "جميع الطلاب",
          number: dashboard?.studentNumbers,
          icon: <i className="la la-users" />,
          color: "secondary",
          maxCapacity: dashboard?.maxStudents,
          unit: "طالب",
        },
        {
          title: "اجمالي الدخل",
          number: dashboard?.totalIncome,
          icon: <i className="la la-dollar" />,
          color: "warning",
          maxCapacity: 20000,
          unit: "جنيه",
        },
        {
          title: "اجمالي المساحة",
          number: (dashboard?.totalUsageStorage / 1024).toFixed(2),
          icon: <i class="la la-hdd"></i>,
          color: "danger",
          maxCapacity: (dashboard?.maxStorage / 1024).toFixed(2),
          unit: "جيجابايت",
        },
      ];

  if (isError) {
    (error.response.data.error === "In valid email or password" ||
      error.response.data.error === "Access Denied") &&
      error.response.status === 403 &&
      Swal.fire({
        title: "خطأ",
        text: "يجب اعادة تسجيل الدخول!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "العودة الي صفحة تسجيل الدخول",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }

  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }
  const HighestCourse = dashboard?.topCourseEnrollment;
  const HighestIncome = dashboard?.topCourseIncome;

  let HighestCourseKeys = [];
  let HighestCourseValues = [];
  let HighestIncomeValues = [];
  let HighestIncomeKeys = [];
  let RestCommunities = 0;
  let RestIncome = 0;

  let IncomeProgress = dashboard?.incomeProgress;
  let StudentProgress = dashboard?.studentProgress;

  HighestCourseKeys = !isLoading && Object.keys(HighestCourse);
  HighestCourseValues = !isLoading && Object.values(HighestCourse);

  HighestIncomeKeys = !isLoading && Object.keys(HighestIncome);
  HighestIncomeValues = !isLoading && Object.values(HighestIncome);
  RestCommunities =
    !isLoading &&
    dashboard?.studentNumbers - HighestCourseValues.reduce((a, b) => a + b, 0);

  RestIncome =
    !isLoading &&
    dashboard?.totalIncome - HighestIncomeValues.reduce((a, b) => a + b, 0);

  return (
    <>
      <Row>
        {bgCarddBlog.map((item, index) => (
          <Col
            xl={"3"}
            xxl={"3"}
            sm={"6"}
            key={index}>
            <div className={`widget-stat card bg-${item.color}`}>
              <div className="card-body">
                <BgCard
                  title={item.title}
                  number={
                    item.title === "Income" ? item.number + " EGP" : item.number
                  }
                  percent={
                    ((item.number / item.maxCapacity) * 100).toFixed(2) + "%"
                  }
                  color={item.color}
                  icon={item.icon}
                  maxCapacity={item.maxCapacity}
                  unit={item.unit}
                />
              </div>
            </div>
          </Col>
        ))}
        <Col
          xl={"6"}
          xxl={"6"}
          sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">تحليل أعلي الدورات تسجيلا</h3>
            </div>
            <div className="card-body d-flex justify-content-center flex-wrap xl:justify-content-center  gap-4">
              <DonughtChart
                data={HighestCourseKeys}
                values={HighestCourseValues}
                total={RestCommunities}
              />
              <div className="doughnut-chart__content col-8 xl:col-6 d-flex flex-column gap-4">
                <h3>أعلي ثلاث دورات</h3>

                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {HighestCourseKeys[0] ? HighestCourseKeys[0] : ""}
                          </Tooltip>
                        }>
                        <h6 className="text-nowrap">
                          {HighestCourseKeys[0]
                            ? HighestCourseKeys[0]
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                            : "Not Available"}
                        </h6>
                      </OverlayTrigger>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {HighestCourseValues[0]
                            ? (
                                (HighestCourseValues[0] /
                                  dashboard?.studentNumbers) *
                                100
                              ).toFixed(1)
                            : Number(0).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCourseValues[0]
                              ? (HighestCourseValues[0] /
                                  dashboard?.studentNumbers) *
                                100
                              : 0
                          }
                          variant="warning"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {HighestCourseKeys[1] ? HighestCourseKeys[1] : ""}
                          </Tooltip>
                        }>
                        <h6 className="text-nowrap">
                          {HighestCourseKeys[1]
                            ? HighestCourseKeys[1]
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                            : "Not Available"}
                        </h6>
                      </OverlayTrigger>
                      <div className="d-flex align-items-center gap-2 ">
                        <div className="">
                          {HighestCourseValues[1]
                            ? (
                                (HighestCourseValues[1] /
                                  dashboard?.studentNumbers) *
                                100
                              ).toFixed(1)
                            : Number(0).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCourseValues[1]
                              ? (HighestCourseValues[1] /
                                  dashboard?.studentNumbers) *
                                100
                              : 0
                          }
                          variant="danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {HighestCourseKeys[2] ? HighestCourseKeys[2] : ""}
                          </Tooltip>
                        }>
                        <h6 className="text-nowrap">
                          {HighestCourseKeys[2]
                            ? HighestCourseKeys[2]
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                            : "Not Available"}
                        </h6>
                      </OverlayTrigger>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {HighestCourseValues[2]
                            ? (
                                (HighestCourseValues[2] /
                                  dashboard?.studentNumbers) *
                                100
                              ).toFixed(1)
                            : Number(0).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCourseValues[2]
                              ? (HighestCourseValues[2] /
                                  dashboard?.studentNumbers) *
                                100
                              : 0
                          }
                          variant="primary"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>اخري</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {" "}
                          {(
                            (RestCommunities / dashboard?.studentNumbers) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (RestCommunities / dashboard?.studentNumbers) * 100
                          }
                          variant="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xl={"6"}
          xxl={"6"}
          sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">تحليل أعلي الدورات دخلا</h3>
            </div>
            <div className="card-body d-flex justify-content-center flex-wrap xl:justify-content-center  gap-4">
              <DonughtChart
                data={HighestIncomeKeys}
                values={HighestIncomeValues}
                total={RestIncome}
              />
              <div className="doughnut-chart__content col-8 xl:col-6 d-flex flex-column gap-4">
                <h3>أعلي ثلاث دورات</h3>

                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {HighestIncomeKeys[0] ? HighestIncomeKeys[0] : ""}
                          </Tooltip>
                        }>
                        <h6 className="text-nowrap">
                          {HighestIncomeKeys[0]
                            ? HighestIncomeKeys[0]
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                            : "Not Available"}
                        </h6>
                      </OverlayTrigger>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {HighestIncomeValues[0]
                            ? (
                                (HighestIncomeValues[0] /
                                  dashboard?.totalIncome) *
                                100
                              ).toFixed(1)
                            : Number(0).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestIncomeValues[0]
                              ? (HighestIncomeValues[0] /
                                  dashboard?.totalIncome) *
                                100
                              : 0
                          }
                          variant="warning"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {HighestIncomeKeys[1] ? HighestIncomeKeys[1] : ""}
                          </Tooltip>
                        }>
                        <h6 className="text-nowrap">
                          {HighestIncomeKeys[1]
                            ? HighestIncomeKeys[1]
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                            : "Not Available"}
                        </h6>
                      </OverlayTrigger>
                      <div className="d-flex align-items-center gap-2 ">
                        <div className="">
                          {HighestIncomeValues[1]
                            ? (
                                (HighestIncomeValues[1] /
                                  dashboard?.totalIncome) *
                                100
                              ).toFixed(1)
                            : Number(0).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestIncomeValues[1]
                              ? (HighestIncomeValues[1] /
                                  dashboard?.totalIncome) *
                                100
                              : 0
                          }
                          variant="danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {HighestIncomeKeys[2] ? HighestIncomeKeys[2] : ""}
                          </Tooltip>
                        }>
                        <h6 className="text-nowrap">
                          {HighestIncomeKeys[2]
                            ? HighestIncomeKeys[2]
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")
                            : "Not Available"}
                        </h6>
                      </OverlayTrigger>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {HighestIncomeValues[2]
                            ? (
                                (HighestIncomeValues[2] /
                                  dashboard?.totalIncome) *
                                100
                              ).toFixed(1)
                            : Number(0).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestIncomeValues[2]
                              ? (HighestIncomeValues[2] /
                                  dashboard?.totalIncome) *
                                100
                              : 0
                          }
                          variant="primary"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>اخري</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {(
                            (RestIncome / dashboard?.totalIncome) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={(RestIncome / dashboard?.totalIncome) * 100}
                          variant="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xxl={"12"}
          xl={"12"}
          sm={"12"}
          lg={"12"}>
          <div className="card h-max">
            <div className="card-header">
              <h4 className="card-title">تحليل بيانات عام</h4>
              <Card>
                <Button onClick={() => generatePDF(dashboard)}>
                  استخراج تقرير
                </Button>
              </Card>
            </div>
            <div className="card-body ">
              <DualArea
                data={[IncomeProgress, StudentProgress]}
                // isIncome
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Home;
