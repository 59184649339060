import React from "react";
import { DatePicker } from "rsuite";
import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation } from "@tanstack/react-query";
import { AddCourseLessons } from "../../../../../API/Courses/SingleCourse/CourseLessons/AddCourseLessons";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";

const lessonSchema = Yup.object().shape({
  lessonNo: Yup.number()
    .required("رقم الدرس مطلوب")
    .positive("يجب أن يكون رقم الدرس موجب")
    .integer("يجب أن يكون رقم الدرس عددًا صحيحًا")
    .typeError(" يجب أن يكون رقم"),
  title: Yup.string()
    .required("العنوان مطلوب")
    .min(3, "يجب أن يكون العنوان 3 أحرف على الأقل")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "يجب أن يكون العنوان أحرفًا وأرقامًا ويمكن أن يتضمن شرطات سفلية وشرطات"
    ),
  openTime: Yup.date()
    .nullable()
    .notRequired()
    .typeError("تنسيق التاريخ غير صالح")
    .test("is-future-date", "يجب أن يكون التاريخ في المستقبل", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    }),
});

const AddLesson = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate } = useMutation({
    mutationFn: (data) => AddCourseLessons(id, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم اضافة الدرس بنجاح",
      });
      queryClient.invalidateQueries({ queryKey: ["CourseLessons"] });
      navigate(`/جميع-الدورات/${id}/الدروس`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });
  return (
    <>
      <PageTitle
        title={"دروس الدورة"}
        activeMenu={"جميع الدورات"}
        motherMenu={"الدورات"}
        userId={id}
        secondMenu={"اضافة درس"}
        goBack={true}
        goTo={`/جميع-الدورات/${id}/الدروس`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">بيانات الدرس</h4>
            </div>

            <Formik
              initialValues={{
                title: "",
                lessonNo: "",
                openTime: null,
              }}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                console.log(values);
                mutate(values, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            العنوان
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="ادخل عنوان الدرس"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="openTime">
                            تاريخ بدء الدرس
                          </label>
                          <DatePicker
                            id="openTime"
                            placeholder="اختار التاريخ"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.openTime && errors.openTime
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.openTime}
                            onChange={(value) =>
                              setFieldValue("openTime", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.openTime && errors.openTime && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.openTime}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lessonNo">
                            رقم الدرس <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lessonNo"
                            placeholder="ادخل رقم الدرس"
                            type="text"
                            name="lessonNo"
                            className={`form-control ${
                              touched.lessonNo && errors.lessonNo
                                ? "is-invalid"
                                : touched.lessonNo
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.lessonNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lessonNo && errors.lessonNo && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.lessonNo}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          اضافة
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          الغاء
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLesson;
