import { Button, Pagination } from "react-bootstrap";

function CustomPagination({ pageNo, setPageNo, totalPages }) {
  let items = [];
  for (let number = 0; number < totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === pageNo}
        onClick={() => setPageNo(number)}>
        {number + 1}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="pagination-gutter pt-4 d-flex justify-content-center">
      <Button
        onClick={() => {
          if (pageNo > 0) setPageNo(pageNo - 1);
        }}
        disabled={pageNo === 0}
        className="btn btn-primary me-2">
        السابق
      </Button>
      {items}
      <Button
        onClick={() => {
          if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
        }}
        disabled={pageNo === totalPages - 1}
        className="btn btn-primary ms-2">
        التالي
      </Button>
    </Pagination>
  );
}

export default CustomPagination;
