import { Button, Row } from "react-bootstrap";

import { useQuery } from "@tanstack/react-query";
import { GetMyProfile } from "../../../API/MyProfile/GetMyProfile";
import { IMAGES } from "../../constant/theme";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { PROFILEIMG } from "../../../util/UploadToS3";

function MyProfile() {
  const navigate = useNavigate();
  const {
    data: MyProfile,
    isLoading: MyProfileLoading,
    isError: MyProfileError,
    error,
  } = useQuery({ queryKey: ["MyProfile"], queryFn: () => GetMyProfile() });

  if (
    MyProfileError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  if (MyProfileLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu={"الملف الشخصي الخاص بي"}
        motherMenu={"الملف الشخصي"}
        noNavigat
      />
      <div className="row ">
        <div className="col-12 ">
          <div className="">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                  <div className="">
                    <div className="card overflow-hidden">
                      <div className="text-center p-3 overlay-box">
                        <div className="profile-photo">
                          <img
                            src={
                              PROFILEIMG + MyProfile.image + "?v=" + Date.now()
                            }
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                              backgroundColor: "#fff",
                            }}
                            className="img-fluid rounded-circle "
                            alt=""
                            onError={(e) => {
                              e.target.src = IMAGES.Avatpng1;
                            }}
                          />
                        </div>
                        <p className="text-white mb-0">
                          {MyProfile.firstName + " " + MyProfile.lastName}
                        </p>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between border-dark  py-3 ">
                          <span className="mb-1">
                            {MyProfile.firstName + " " + MyProfile.lastName}
                          </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                          <span className="mb-1">#{MyProfile.id}</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                          <span className="mb-1">{MyProfile.email}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-7 col-md-7 col-sm-12 mt-4 rounded p-4 card">
                  <div className="profile-personal-info">
                    <div className="d-flex justify-content-between border-bottom border-dark pb-2">
                      <div className="">
                        <h3 className="text-dark py-2">
                          عن {MyProfile.firstName + " " + MyProfile.lastName}
                        </h3>
                      </div>
                      <Button
                        variant="primary mb-3"
                        onClick={() =>
                          navigate("/الملف-الشخصي/تعديل-الملف-الشخصي")
                        }>
                        تحديث
                        {"  "}
                        <i className="fa fa-pencil" />
                      </Button>
                    </div>

                    <h3 className="text-primary my-4">المعلومات الشخصية</h3>

                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          الاسم الاول
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>{MyProfile.firstName}</span>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          الاسم الاخير
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>{MyProfile.lastName}</span>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          البريد الالكتروني
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>{MyProfile.email}</span>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          الدولة
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>{MyProfile.country}</span>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          رقم الهاتف
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>+{MyProfile.phone}</span>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          تاريخ التسجيل
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>
                          {new Intl.DateTimeFormat("ar-EG", {
                            dateStyle: "full",
                          }).format(new Date(MyProfile.registerDate))}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="profile-lang pt-5 border-bottom-1 pb-5">
                    <h3 className="text-primary mb-4">اللغة</h3>
                    <span className="badge badge-primary light">
                      {MyProfile.language}
                    </span>
                  </div>
                  <div className="profile-lang pt-5 border-bottom-1 pb-5">
                    <h3 className="text-primary mb-4">الخبرة</h3>

                    <div className="row mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                        <h5 className="f-w-500">
                          سنوات الخبرة
                          <span className="pull-right">:</span>
                        </h5>
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                        <span>{MyProfile.experience} سنوات</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProfile;
