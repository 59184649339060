import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";
import { UploadToS3 } from "../../../../../util/UploadToS3";
import { AddCourseLessonsFile } from "../../../../../API/Courses/SingleCourse/CourseLessons/AddCourseLessonsFile";
import { v4 as uuidv4 } from "uuid";

const lessonSchema = Yup.object().shape({
  fileNo: Yup.number()
    .required("رقم الملف مطلوب")
    .positive("يجب أن يكون رقم الملف موجبًا")
    .integer("يجب أن يكون رقم الملف عددًا صحيحًا")
    .typeError("درجة الامتحان يجب أن يكون رقم"),
  title: Yup.string()
    .required("العنوان مطلوب")
    .min(3, "يجب أن يكون العنوان 3 أحرف على الأقل")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "يجب أن يكون العنوان أحرفًا وأرقامًا ويمكن أن يتضمن شرطات سفلية وشرطات"
    ),
  file: Yup.mixed()
    .required("الملف مطلوب")
    .test(
      "fileType",
      "تنسيق الملف غير مدعوم. مقاطع الفيديو فقط مقبولة.",
      (value) => {
        if (!value) return false; // Check if there is a file
        // Check if the file type starts with 'video/'
        return value.type.startsWith("video/");
      }
    ),
});

const AddLessonVideo = () => {
  const navigate = useNavigate();
  const { id, lessonId } = useParams();
  const uniqueId = uuidv4();
  const { mutate } = useMutation({
    mutationFn: (data) => AddCourseLessonsFile(id, lessonId, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم اضافة فيديو الدرس بنجاح",
      });
      queryClient.invalidateQueries({ queryKey: ["CourseLessons"] });
      navigate(`/جميع-الدورات/${id}`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });
  return (
    <>
      <PageTitle
        title={"دروس الدورة"}
        activeMenu={"جميع الدورات"}
        motherMenu={"الدورات"}
        userId={id}
        secondMenu={"اضافة فيديو الدرس"}
        goBack={true}
        goTo={`/جميع-الدورات/${id}/الدروس`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">تفاصيل فديو الدرس</h4>
            </div>

            <Formik
              initialValues={{
                title: "",
                fileNo: "",
                file: null,
              }}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const { file, ...restValues } = values;

                mutate(restValues, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    UploadToS3(
                      `${restValues.path}`,
                      file,
                      "Teacher/CourseVideos/"
                    );
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            عنوان الفديو
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="ادخل عنوان الفديو"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="fileNo">
                            رقم الدرس <span className="text-danger">*</span>
                          </label>
                          <input
                            id="fileNo"
                            placeholder="ادخل رقم فديو الدرس"
                            type="text"
                            name="fileNo"
                            className={`form-control ${
                              touched.fileNo && errors.fileNo
                                ? "is-invalid"
                                : touched.fileNo
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.fileNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.fileNo && errors.fileNo && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.fileNo}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="file"
                            className="form-label">
                            فديو الدرس
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className={`form-control ${
                              touched.file
                                ? errors.file
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            type="file"
                            id="file"
                            name="file"
                            onChange={(e) => {
                              console.log(e);
                              setFieldValue("file", e.currentTarget.files[0]);
                              setFieldValue(
                                "size",
                                e.currentTarget.files[0].size
                              );
                              setFieldValue(
                                "path",
                                `${uniqueId}-${e.currentTarget.files[0].name}`
                              );
                              const isVideo =
                                e.currentTarget.files[0].type.startsWith(
                                  "video/"
                                );

                              isVideo && setFieldValue("isVideo", true);
                            }}
                          />
                          {touched.file && errors.file && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.file}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          اضافة
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          الغاء
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLessonVideo;
