import { Row } from "react-bootstrap";
import { IMAGES } from "../../../constant/theme";

function Personal({ PersonalData, PesronalLoading, PesronalError, error }) {
  if (
    PesronalError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  if (PesronalLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  return (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        <div className="">
          <div className="card overflow-hidden">
            <div className="text-center p-3 overlay-box">
              <div className="profile-photo">
                <img
                  src={"url/" + PersonalData.image}
                  width="100"
                  className="img-fluid rounded-circle"
                  alt=""
                  onError={(e) => {
                    e.target.src = IMAGES.Avatpng1;
                  }}
                />
              </div>
              <p className="text-white mb-0">
                {PersonalData.firstName + " " + PersonalData.lastName}
              </p>
            </div>
            <ul className="list-group list-group-flush ">
              <li className="list-group-item d-flex justify-content-between border-dark  py-3 ">
                <span className="mb-1">
                  {PersonalData.firstName + " " + PersonalData.lastName}
                </span>
              </li>
              <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                <span className="mb-1">#{PersonalData.id}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                <span className="mb-1">{PersonalData.email}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-9 col-lg-7 col-md-7 col-sm-12 card mt-4 rounded p-4">
        <div className="profile-personal-info">
          <h3 className="text-dark  mb-4 border-bottom pb-2 border-dark">
            عن {PersonalData.firstName + " " + PersonalData.lastName}
          </h3>

          <h3 className="text-primary mb-4">البيانات الشخصية</h3>

          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                الاسم الاول
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>{PersonalData.firstName}</span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                الاسم الاخير
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>{PersonalData.lastName}</span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                البريد الالكتروني
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>{PersonalData.email}</span>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                الدولة
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>{PersonalData.country}</span>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                الهاتف المحمول
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>+{PersonalData.phone}</span>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                العنوان
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>
                {PersonalData.address}, {PersonalData.city}
              </span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                الحاله
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>{PersonalData.active ? "Active" : "Not Active"}</span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                التحقق
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>
                {PersonalData.verified ? "تم التحقق" : "لم يتم التحقق"}
              </span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                تاريخ التسجيل
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>
                {new Intl.DateTimeFormat("ar-EG", {
                  dateStyle: "full",
                }).format(new Date(PersonalData.registerDate))}
              </span>
            </div>
          </div>
        </div>

        <div className="profile-lang pt-5 border-bottom-1 pb-5">
          <h3 className="text-primary mb-4">اللغة</h3>
          <span className="badge badge-primary light">
            {PersonalData.language}
          </span>
        </div>
        <div className="profile-lang pt-5 border-bottom-1 pb-5">
          <h3 className="text-primary mb-4">المهارات</h3>

          <div className="row mb-4">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <h5 className="f-w-500">
                المستوى التعليمي
                <span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
              <span>{PersonalData.eduLevel.label} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;
