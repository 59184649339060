import React, { useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
/// Css
import "./../index.css";
import "./../chart.css";
import "./../step.css";

/// Layout
import Nav from "./../layouts/nav";
import Footer from "./../layouts/Footer";

import { ThemeContext } from "../../context/ThemeContext";
//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Dashboard
import Home from "./../pages/dashboard/Home";

import EmptyPage from "./../pages/dashboard/EmptyPage";

//Event
import EventManagement from "./../pages/dashboard/EventManagement";

//Student
import AllStudent from "./../pages/students/AllStudent";
import AddStudent from "./../pages/students/AddStudent";
import EditStudent from "./../pages/students/EditStudent";
import AboutStudent from "./../pages/students/AboutStudent";

//Course
import AllCourses from "./../pages/courses/AllCourses";
import AddCourses from "./../pages/courses/AddCourses";
import EditCourses from "./../pages/courses/EditCourses";
import AboutCourses from "./../pages/courses/AboutCourses";

/// Charts
import RechartJs from "./../pages/charts/rechart";
import ChartJs from "./../pages/charts/Chartjs";
import SparklineChart from "./../pages/charts/Sparkline";
import ApexChart from "./../pages/charts/apexcharts";

/// Pages
import LockScreen from "./../pages/error/LockScreen";
import Error400 from "./../pages/error/Error400";
import Error403 from "./../pages/error/Error403";
import Error404 from "./../pages/error/Error404";
import Error500 from "./../pages/error/Error500";
import Error503 from "./../pages/error/Error503";
import AllAnnouncements from "../pages/Announcements/AllAnnouncements";
import AddLesson from "../pages/courses/CourseParts/Lesssons/AddLesson";
import EditLesson from "../pages/courses/CourseParts/Lesssons/EditLesson";
import AddLessonVideo from "../pages/courses/CourseParts/Lesssons/AddLessonVideo";
import AddLessonFile from "../pages/courses/CourseParts/Lesssons/AddLessonFile";
import AddAssignment from "../pages/courses/CourseParts/Assignments/AddAssignment";
import EditAssignment from "../pages/courses/CourseParts/Assignments/EditAssignment";
import AssginmentsPage from "../pages/courses/CourseParts/Assignments/Submissions/AssginmentsPage";
import AddExam from "../pages/courses/CourseParts/Exams/AddExam";
import EditExam from "../pages/courses/CourseParts/Exams/EditExam";
import SingleExam from "../pages/courses/CourseParts/Exams/SingleExam";
import AddExamQuestion from "../pages/courses/CourseParts/Exams/ExamParts/AddExamQuestion";
import EditExamQuestion from "../pages/courses/CourseParts/Exams/ExamParts/EditExamQuestion";
import ExamSubbmitionsFeedback from "../pages/courses/CourseParts/Exams/ExamParts/ExamSubbmitionsFeedback";
import MyProfile from "../pages/MyProfile/MyProfile";
import EditMyProfile from "../pages/MyProfile/EditMyProfile";
import Transactions from "../pages/Transactions/Transactions";
import AddAnnouncement from "../pages/Announcements/AddAnnouncement";
import EditAnnouncement from "../pages/Announcements/EditAnnouncement";

// import Todo from './pages/Todo';

const Markup = () => {
  const allroutes = [
    /// Dashboard
    {
      url: "",
      component: (
        <Navigate
          to="/لوحة-التحكم"
          replace
        />
      ),
    },
    { url: "لوحة-التحكم", component: <Home /> },

    { url: "ادارة-الاحداث", component: <EventManagement /> },

    //Profile

    { url: "الملف-الشخصي-الخاص بي", component: <MyProfile /> },
    { url: "الملف-الشخصي/تعديل-الملف-الشخصي", component: <EditMyProfile /> },

    //Student
    { url: "جميع-الطلاب", component: <AllStudent /> },
    { url: "جميع-الطلاب/اضافة-طالب", component: <AddStudent /> },
    { url: "جميع-الطلاب/تعديل-طالب/:id", component: <EditStudent /> },
    {
      url: "/جميع-الطلاب/:id/submissions?/:submissionId?/feedback?/:feedbackId?",
      component: <AboutStudent />,
    },

    //Registeration
    { url: "جميع-التسجيلات", component: <div>registeration</div> },
    { url: "اضافة-تسجيل", component: <div>registeration</div> },
    { url: "تعديل-تسجيل", component: <div>registeration</div> },
    { url: "/جميع-التسجيلات/:id", component: <div>registeration</div> },
    //Messages
    { url: "جميع-الرسائل", component: <div>Messages</div> },
    { url: "add-messages", component: <div>Messages</div> },
    { url: "edit-messages", component: <div>Messages</div> },
    { url: "/جميع-الرسائل/:id", component: <div>Messages</div> },

    //Course
    { url: "جميع-الدورات", component: <AllCourses /> },
    { url: "جميع-الدورات/اضافة-دورة", component: <AddCourses /> },
    { url: "جميع-الدورات/تعديل-دورة/:id", component: <EditCourses /> },
    { url: "جميع-الدورات/:id/:tab?", component: <AboutCourses /> },
    { url: "جميع-الدورات/:id/اضافة-درس", component: <AddLesson /> },
    {
      url: "جميع-الدورات/:id/اضافة-درس-فايل/:lessonId",
      component: <AddLessonFile />,
    },
    {
      url: "جميع-الدورات/:id/اضافة-درس-فيديو/:lessonId",
      component: <AddLessonVideo />,
    },
    {
      url: "جميع-الدورات/:id/تعديل-درس/:lessonId",
      component: <EditLesson />,
    },

    { url: "جميع-الدورات/:id/اضافة-واجب", component: <AddAssignment /> },
    {
      url: "جميع-الدورات/:id/تعديل-واجب/:assignmentId",
      component: <EditAssignment />,
    },
    {
      url: "جميع-الدورات/:id/التقديمات/:submissionId/feedback?/:feedbackId?",
      component: <AssginmentsPage />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/اضافة-امتحان",
      component: <AddExam />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/تعديل-امتحان/:examId",
      component: <EditExam />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/:examId/:tab?",
      component: <SingleExam />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/:examId/الاسئلة?/اضافة-سؤال",
      component: <AddExamQuestion />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/:examId/الاسئلة?/:questionId/تعديل-سؤال",
      component: <EditExamQuestion />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/:examId/التسليمات?/:submissionId/التقييمات?",
      component: <ExamSubbmitionsFeedback />,
    },
    {
      url: "جميع-الدورات/:id/الامتحانات?/:examId/:tab?",
      component: <SingleExam />,
    },

    // Transactions
    { url: "جميع-المعاملات", component: <Transactions /> },
    // Announcements
    { url: "جميع-التنبيهات", component: <AllAnnouncements /> },
    {
      url: "جميع-التنبيهات/اضافة-تنبيه",
      component: <AddAnnouncement />,
    },
    {
      url: "جميع-التنبيهات/:id/تعديل-تنبيه",
      component: <EditAnnouncement />,
    },
    /// pages
    { url: "empty", component: <EmptyPage /> },
    // { url: 'todo', component: <Todo/> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = decodeURIComponent(path.split("/"));
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/page-lock-screen"
          element={<LockScreen />}
        />
        <Route
          path="/page-error-400"
          element={<Error400 />}
        />
        <Route
          path="/page-error-403"
          element={<Error403 />}
        />
        <Route
          path="/page-error-404"
          element={<Error404 />}
        />
        <Route
          path="/page-error-500"
          element={<Error500 />}
        />
        <Route
          path="/page-error-503"
          element={<Error503 />}
        />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector((state) => state.sideMenu);
  return (
    <>
      <div
        id="main-wrapper"
        className={`show  ${sidebariconHover ? "iconhover-toggle" : ""} ${
          sideMenu ? "menu-toggle" : ""
        }`}>
        <Nav />
        <div className="content-body">
          <div
            className="container-fluid"
            style={{ minHeight: window.screen.height - 45 }}>
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Markup;
