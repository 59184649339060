import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../../../../../App";
import Swal from "sweetalert2";
import PageTitle from "../../../../layouts/PageTitle";
import FilterCourses from "../../FilterCourses";
import { Button, Nav, Tab } from "react-bootstrap";
import { RiAddBoxFill } from "react-icons/ri";
import { DeleteCouresExam } from "../../../../../API/Courses/SingleCourse/CourseExams/DeleteCouresExam";
import ExamOverview from "./ExamParts/ExamOverview";
import ExamQuestions from "./ExamParts/ExamQuestions";
import ExamSubbmitions from "./ExamParts/ExamSubbmitions";
import ExamAppeal from "./ExamParts/ExamAppeal";

const SingleExam = () => {
  const { id, examId, tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(tab || "نظرةعامة");
  const [filters, setFilters] = useState({
    نظرةعامة: {},
    الاسئلة: {},
    التسليمات: { search: "", date: "" },
    التظلمات: { search: "", date: "" },
  });

  console.log(activeTab, tab);
  useEffect(() => {
    setActiveTab(tab || "نظرةعامة"); // Set active tab based on URL parameter
  }, [tab, location, activeTab]);

  const { mutate } = useMutation({
    mutationFn: () => DeleteCouresExam(id, examId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CouresExams"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ! ",
      });
    },
  });

  function deleteCourse() {
    Swal.fire({
      title: "Are you sure to you want to delete this exam ?",
      text: "You will not be able to recover this exam file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "نعم, تأكيد الحذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate();
        Swal.fire(
          "Deleted!",
          `Course with id #${id} has been deleted successfully.`,
          "success"
        );
        navigate(`/جميع-الدورات/${id}/الامتحانات`);
      }
    });
  }

  const handleTabSelect = (selectedKey) => {
    // Update filters based on the selected tab
    setActiveTab(() => selectedKey);
    // Update state or perform actions based on the selected tab
    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedKey]: { search: "", date: "" },
    }));
    navigate(`/جميع-الدورات/${id}/الامتحانات/${examId}/${selectedKey}`);
  };
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [activeTab]: newFilters,
    }));
  };

  return (
    <>
      <PageTitle
        title={"جميع الامتحانات"}
        activeMenu={"جميع الدورات"}
        motherMenu={"الدورات"}
        userId={id}
        secondId={examId}
        secondMenu={"الامتحانات"}
        goBack
        goTo={`/جميع-الدورات/${id}/الامتحانات`}
      />
      <FilterCourses
        activeTab={activeTab}
        filters={filters[activeTab]}
        onFilterChange={handleFilterChange}
      />

      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container
                        onSelect={handleTabSelect}
                        defaultActiveKey={activeTab || tab}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs lg:d-flex w-100">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="نظرةعامة">نظرةعامة</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="الاسئلة">الاسئلة</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="التسليمات">التسليمات</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="التظلمات">التظلمات</Nav.Link>
                          </Nav.Item>
                          <Nav className="ms-auto mt-4 mt-lg-0">
                            {(tab || activeTab) === "نظرةعامة" && (
                              <div className="d-flex gap-3 align-items-center">
                                <Link
                                  to={`/جميع-الدورات/${id}/تعديل-امتحان/${examId}`}>
                                  <Button variant="primary">
                                    تعديل
                                    {"  "}
                                    <i className="fa fa-pencil" />
                                  </Button>
                                </Link>

                                <Link onClick={() => deleteCourse()}>
                                  <Button variant="danger">
                                    حذف
                                    {"  "}
                                    <i className="fa fa-trash" />
                                  </Button>
                                </Link>
                              </div>
                            )}
                            {(tab || activeTab) === "الاسئلة" && (
                              <Link
                                to={`/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة/اضافة-سؤال`}>
                                <Button variant="primary">
                                  اضافة سؤال <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                          </Nav>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"نظرةعامة"}>
                            {(activeTab || tab) === "نظرةعامة" && (
                              <ExamOverview
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"الاسئلة"}>
                            {(activeTab || tab) === "الاسئلة" && (
                              <ExamQuestions
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"التسليمات"}>
                            {(activeTab || tab) === "التسليمات" && (
                              <ExamSubbmitions
                                search={filters.التسليمات.search}
                                date={filters.التسليمات.date}
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"التظلمات"}>
                            {(activeTab || tab) === "التظلمات" && (
                              <ExamAppeal
                                search={filters.التظلمات.search}
                                date={filters.التظلمات.date}
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleExam;
