export const STATUS = [
  {
    label: "اختر الحالة",
    value: "",
  },
  {
    label: "معلق",
    value: "PENDING",
  },
  {
    label: "مرفوض",
    value: "DECLINED",
  },
  {
    label: "مقبول",
    value: "APPROVED",
  },
];
export const TRANSACTIONTYPE = [
  {
    label: "اختر نوع العملية",
    value: "",
  },
  {
    label: "ايداع",
    value: "DEPOSIT",
  },
  {
    label: "سحب",
    value: "WITHDRAW",
  },
];

export const QUESTIONSTYPE = [
  {
    label: "سؤال_مقالي",
    value: "TEXT_BASED",
  },
  {
    label: "اختيار_من_متعدد",
    value: "MCQ",
  },
];
