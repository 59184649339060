import React from "react";
import { DatePicker } from "rsuite";
import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation, useQuery } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";
import { GetSingleLesson } from "../../../../../API/Courses/SingleCourse/CourseLessons/GetSingleLesson";
import { Row } from "react-bootstrap";
import { EditCourseLessons } from "../../../../../API/Courses/SingleCourse/CourseLessons/EditCourseLessons";

const lessonSchema = Yup.object().shape({
  lessonNo: Yup.number()
    .positive("يجب أن يكون رقم الدرس موجبًا")
    .integer("يجب أن يكون رقم الدرس عددًا صحيحًا"),
  title: Yup.string().min(3, "يجب أن يتكون العنوان من 3 أحرف على الأقل"),
  openTime: Yup.date()
    .nullable()
    .notRequired()
    .typeError("تنسيق التاريخ غير صالح"),
});

const EditLesson = () => {
  const navigate = useNavigate();
  const { id, lessonId } = useParams();

  const {
    data: SingleLesson,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["CourseLessons", id, lessonId],
    queryFn: () => GetSingleLesson(id, lessonId),
    onSuccess: () => {},
  });
  const { mutate } = useMutation({
    mutationFn: (data) => EditCourseLessons(id, lessonId, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "تم تعديل الدرس بنجاح",
      });
      queryClient.invalidateQueries({ queryKey: ["CourseLessons"] });
      navigate(`/جميع-الدورات/${id}`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  const initialValues = {
    title: SingleLesson?.title || "",
    lessonNo: SingleLesson?.lessonNo || "",
    openTime: SingleLesson?.openTime ? new Date(SingleLesson.openTime) : null, // Convert string to Date object
  };
  console.log(initialValues);
  return (
    <>
      <PageTitle
        title={"دروس الدورة"}
        activeMenu={"جميع الدورات"}
        motherMenu={"الدورات"}
        userId={id}
        secondMenu={"تعديل درس"}
        goBack={true}
        goTo={`/جميع-الدورات/${id}/الدروس`}
        secondId={lessonId}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">تعديل درس #{id}</h4>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                if (Object.keys(changedValues).length === 0) {
                  // If no fields were changed, do not submit and possibly alert the user
                  Swal.fire({
                    icon: "info",
                    title: "لا يوجد تغييرات",
                    text: "لا يوجد تغييرات بين القيم القديمة والجديدة.",
                  });
                  actions.setSubmitting(false);
                  return; // Stop the onSubmit processing here
                }
                mutate(changedValues, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            عنوان الدرس
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="ادخل عنوان الدرس"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="openTime">
                            تاريخ البدء
                          </label>
                          <DatePicker
                            id="openTime"
                            placeholder="اختر تاريخ البدء"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.openTime && errors.openTime
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.openTime}
                            onChange={(value) =>
                              setFieldValue("openTime", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.openTime && errors.openTime && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.openTime}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lessonNo">
                            رقم الدرس <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lessonNo"
                            placeholder="ادخل رقم الدرس"
                            type="text"
                            name="lessonNo"
                            className={`form-control ${
                              touched.lessonNo && errors.lessonNo
                                ? "is-invalid"
                                : touched.lessonNo
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.lessonNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lessonNo && errors.lessonNo && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.lessonNo}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          اضافة
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          الغاء
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLesson;
