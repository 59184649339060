import React from "react";
import PageTitle from "../../layouts/PageTitle";
import CalendarBlog from "../holidays/CalendarBlog";

const EventManagement = () => {
  return (
    <>
      <PageTitle
        title={"الاحداث"}
        activeMenu={"ادارة الاحداث"}
        motherMenu={" الاحداث"}
      />
      <CalendarBlog />
    </>
  );
};

export default EventManagement;
