import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  Button,
  Dropdown,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { queryClient } from "../../../../../App";
import Swal from "sweetalert2";
import { GetAllAssignments } from "../../../../../API/Courses/SingleCourse/CourseAssignments/GetAllAssignments";
import { DeleteAssignment } from "../../../../../API/Courses/SingleCourse/CourseAssignments/DeleteAssignment";
import truncateString from "../../../../../util/truncateString";

const theadData = [
  { heading: "الرقم التعريفي" },
  { heading: "العنوان" },
  { heading: "الوصف" },
  { heading: "تاريخ الافتتاح" },
  { heading: "الموعد النهائي" },
  { heading: "الاجراء" },
];

function CourseAssignments({ search, openDate }) {
  const [sort, setSortata] = useState(10);
  const { id } = useParams();
  const navigate = useNavigate();
  const activePag = useRef(0);
  const [pageNo, setPageNo] = useState(0);
  const [filteredAssignments, setFilteredAssignments] = useState([]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["CourseAssignments", id],
    queryFn: () => GetAllAssignments(id),
  });

  const { mutate: DeleteLessonAssignment } = useMutation({
    mutationFn: (assignmentId) => DeleteAssignment(id, assignmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CourseAssignments"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  function deleteAssignment(id) {
    Swal.fire({
      title: "هل انت متأكد من حذف هذا الواجب؟",
      text: "لن تستطيع استرجاع هذا الواجب مرة اخرى!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "نعم, تأكيد الحذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteLessonAssignment(id);
        Swal.fire("تم الحذف!", `تم حذف الواجب  رقم ${id} بنجاح`, "success");
      }
    });
  }

  useEffect(() => {
    if (data) {
      const filtered = data.filter((assigngment) => {
        const matchesTitle = search
          ? assigngment.title.toLowerCase().includes(search.toLowerCase()) ||
            assigngment.id.toString() === search
          : true;

        const matchesDate = openDate
          ? new Date(assigngment.openDate).toISOString() >=
            new Date(openDate).toISOString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredAssignments(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [data, search, id, openDate]);
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  let paggination =
    Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1) || [];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <Button
        variant="link"
        onClick={decoratedOnClick}>
        {children}
      </Button>
    );
  }

  return (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                عرض
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {sort}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSortata("10")}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortata("20")}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortata("30")}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                عنصر
              </label>
            </div>
          </div>
          <Accordion>
            <table className="table">
              <thead>
                <tr>
                  {theadData.map((item, ind) => (
                    <th key={ind}>{item.heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!filteredAssignments || filteredAssignments.length === 0 ? (
                  <p className="pt-5">لا يوجد بيانات</p>
                ) : (
                  filteredAssignments.map((assignment, index) => (
                    <Fragment key={assignment.id}>
                      <tr>
                        <td>
                          <CustomToggle
                            className="m-0 p-0"
                            eventKey={`${index}`}>
                            <i
                              class="fa-solid fa-chevron-down fa-lg"
                              style={{
                                color: "#888",
                                cursor: "pointer",
                                marginLeft: "-20px",
                              }}></i>
                          </CustomToggle>
                          <strong>{assignment.id}</strong>
                        </td>
                        <td>
                          <strong>{assignment.title}</strong>
                        </td>
                        <td>
                          <strong>
                            {truncateString(assignment.description, 5)}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {new Intl.DateTimeFormat("ar-EG", {
                              dateStyle: "full",
                            }).format(new Date(assignment.openDate))}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {new Intl.DateTimeFormat("ar-EG", {
                              dateStyle: "full",
                            }).format(new Date(assignment.deadline))}
                          </strong>
                        </td>

                        <td className="d-flex justify-content-between align-items-center gap-3">
                          <Link
                            to={`/جميع-الدورات/${id}/التقديمات/${assignment.id}`}
                            className="btn sharp btn-primary w-75">
                            صفحة الوجبات <i className="fa fa-arrow-left" />
                          </Link>
                          <button
                            onClick={() =>
                              navigate(
                                `/جميع-الدورات/${id}/تعديل-واجب/${assignment.id}`
                              )
                            }
                            className="btn sharp btn-primary">
                            <i className="fa fa-pencil" />
                          </button>
                          <button
                            onClick={() => deleteAssignment(assignment.id)}
                            className="btn sharp btn-danger">
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6">
                          <Accordion.Collapse eventKey={`${index}`}>
                            <p>{assignment.description}</p>
                          </Accordion.Collapse>
                        </td>
                      </tr>
                    </Fragment>
                  ))
                )}
              </tbody>
            </table>
          </Accordion>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              عرض {activePag.current * sort + 1} الي{" "}
              {isError
                ? 0
                : data?.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data?.length}{" "}
              من {data?.length} عنصر
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                السابق
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="#"
                    className={`paginate_button  ${
                      pageNo === i ? "current" : ""
                    } `}
                    onClick={() => setPageNo(i)}>
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < data?.totalPages - 1) setPageNo(pageNo + 1);
                }}>
                التالي
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseAssignments;
