import React, { useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Nav, Tab } from "react-bootstrap";
import { GetSingleStudent } from "../../../API/Students/SingleSutdent/GetSingleStudent";
import Filter from "./Filter";
import Swal from "sweetalert2";
import { SuspendStudent } from "../../../API/Students/SuspendStudent";
import { queryClient } from "../../../App";
import { DeleteStudent } from "../../../API/Students/DeleteStudent";
import Personal from "./StudentsParts/Personal";
import Courses from "./StudentsParts/Courses";
import Submissions from "./StudentsParts/Submissions";
import Exams from "./StudentsParts/Exams";
import Appeal from "./StudentsParts/Appeal";

const AboutStudent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("نظرةعامة");
  const [filters, setFilters] = useState({
    نظرةعامة: {},
    الدورات: { search: "", date: "" },
    التسليمات: { search: "", date: "" },
    الامتحانات: { search: "", date: "" },
    التظلمات: { search: "", date: "" },
  });

  // fetch student personal data
  const {
    data: PersonalData,
    isLoading: PesronalLoading,
    isError: PesronalError,
    error,
  } = useQuery({
    queryKey: ["SingleStudent", id],
    queryFn: () => GetSingleStudent(id),
  });

  const { mutate: SuspendTheStudent } = useMutation({
    mutationFn: () => SuspendStudent(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleStudent"] });
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: PersonalData.active
          ? "تم تعطيل الطالب بنجاح"
          : "تم تفعيل الطالب بنجاح",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });
  const { mutate } = useMutation({
    mutationFn: () => DeleteStudent(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllStudents"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  function deleteStudent(id) {
    Swal.fire({
      title: "هل انت متأكد من حذف هذا الطالب ؟",
      text: "لن تستطيع استعادة البيانات بعد الحذف !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "نعم, تأكيد الحذف !",
      cancelButtonText: "إلغاء",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id);
        Swal.fire(
          "تم الحذف!",
          `تم حذف الطالب بالرقم التعريفي ${id} بنجاح.`,
          "success"
        );
        navigate("/جميع-الطلاب");
      }
    });
  }

  const handleTabSelect = (selectedKey) => {
    // Update filters based on the selected tab
    setActiveTab(() => selectedKey);
    // Update state or perform actions based on the selected tab
    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedKey]: { search: "", date: "" },
    }));
    navigate(`/جميع-الطلاب/${id}/${selectedKey}`);
  };
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [activeTab]: newFilters,
    }));
  };

  return (
    <>
      <PageTitle
        activeMenu={"جميع الطلاب"}
        motherMenu={"الطلاب"}
        userId={id}
        goBack={true}
      />

      <Filter
        activeTab={activeTab}
        filters={filters[activeTab]}
        onFilterChange={handleFilterChange}
      />
      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container
                        onSelect={handleTabSelect}
                        defaultActiveKey={"نظرةعامة"}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs lg:d-flex">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="نظرةعامة">نظرة عامة</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="الدورات">الدورات </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="التسليمات">التسليمات</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="الامتحانات">
                              الامتحانات
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="التظلمات">التظلمات</Nav.Link>
                          </Nav.Item>
                          <Nav className="ms-auto mt-4 mt-lg-0">
                            <div className="d-flex gap-3 align-items-center">
                              <Link
                                to={"/جميع-الطلاب/تعديل-طالب/" + id}
                                className="btn sharp btn-primary px-3 ">
                                تعديل
                                {"  "}
                                <i className="fa fa-pencil" />
                              </Link>
                              <Link
                                onClick={() => SuspendTheStudent(id)}
                                className="btn sharp btn-primary px-3 ">
                                {!PesronalLoading &&
                                !PesronalError &&
                                PersonalData.active
                                  ? "ايقاف"
                                  : "تفعيل"}
                              </Link>
                              <Link
                                onClick={() => deleteStudent(id)}
                                className="btn sharp btn-danger px-3 ">
                                حذف
                                {"  "}
                                <i className="fa fa-trash" />
                              </Link>
                            </div>
                          </Nav>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"نظرةعامة"}>
                            <Personal
                              PersonalData={PersonalData}
                              PesronalLoading={PesronalLoading}
                              PesronalError={PesronalError}
                              error={error}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"الدورات"}>
                            {activeTab === "الدورات" && (
                              <Courses
                                id={id}
                                title={filters.الدورات.search}
                                enrollDate={filters.الدورات.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"التسليمات"}>
                            {activeTab === "التسليمات" && (
                              <Submissions
                                id={id}
                                title={filters.التسليمات.search}
                                startDate={filters.التسليمات.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"الامتحانات"}>
                            {activeTab === "الامتحانات" && (
                              <Exams
                                id={id}
                                title={filters.الامتحانات.search}
                                startDate={filters.الامتحانات.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"التظلمات"}>
                            {activeTab === "التظلمات" && (
                              <Appeal
                                id={id}
                                title={filters.التظلمات.search}
                                startDate={filters.التظلمات.date}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutStudent;
