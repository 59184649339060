import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GetExamSubmission } from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamSubmissions/GetExamSubmission";
import { IMAGES } from "../../../../../constant/theme";

function ExamSubbmitions({ search, date, id, examId }) {
  const [filteredExams, setFilteredExams] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const theadData = [
    { heading: "الرقم التعريفي للتسليمة" },
    { heading: "الرقم التعريفي للطالب" },
    { heading: "اسم الطالب" },
    { heading: "البريد الالكتروني للطالب" },
    { heading: "الدرجة" },
    { heading: "تاريخ التسليم" },
    { heading: "التقييم" },
  ];

  const {
    data: ExamsSubmissions,
    isLoading: isLoadingExams,
    isError: isErrorExams,
    error,
  } = useQuery({
    queryKey: ["ExamsSubmissions", id, examId],
    queryFn: () => GetExamSubmission(id, examId),
  });

  useEffect(() => {
    if (ExamsSubmissions) {
      const filtered = ExamsSubmissions.filter((submission) => {
        const matchesSubmissionId = search
          ? submission.submissionId.toString() === search
          : true;
        const matchesStudentId = search
          ? submission.studentId.toString() === search
          : true;
        const matchesDate = date
          ? new Date(submission.submissionDate).toDateString() ===
            new Date(date).toDateString()
          : true;
        return (matchesSubmissionId || matchesStudentId) && matchesDate;
      });
      setFilteredExams(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [ExamsSubmissions, search, date]);

  const totalPages = Math.ceil(filteredExams.length / itemsPerPage);

  const paginatedExams = filteredExams.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  if (isLoadingExams) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorExams &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  return filteredExams.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        لا يوجد بيانات
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                عرض
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                عنصر
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedExams.map((data, ind) => (
                <tr key={data.submissionId}>
                  <td>
                    <strong>{data.submissionId}</strong>
                  </td>
                  <td>
                    <strong>{data.studentId}</strong>
                  </td>
                  <td>
                    <img
                      className="rounded-circle me-2"
                      width="35"
                      src={data.studentImage}
                      alt="Person"
                      onError={(e) => {
                        e.target.src = IMAGES.Avatpng1;
                      }}
                    />
                    {data.studentName}
                  </td>

                  <td>{data.studentEmail}</td>
                  <td className="text-center">
                    {data.score || 0}%{" "}
                    {/* <Button
                      onClick={() => handleOpenModal(data.id)}
                      className="ms-2"
                      variant="outline-primary">
                      <i className="fa fa-pencil" />
                      {"  "}Primary
                    </Button>
                    {changeScore === data.id && (
                      <Modal
                        className="fade"
                        show={changeScore === data.id}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={handleCloseModal}>
                        <Modal.Header>
                          <Modal.Title>Modal title</Modal.Title>
                          <Button
                            variant="close"
                            className="btn-close"
                            onClick={handleCloseModal}></Button>
                        </Modal.Header>
                        <Modal.Body>Modal body text goes here.</Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={handleCloseModal}>
                            Close
                          </Button>
                          <Button
                            variant=""
                            type="button"
                            className="btn btn-primary">
                            Save changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )} */}
                  </td>
                  <td>
                    {new Intl.DateTimeFormat("ar-EG", {
                      dateStyle: "full",
                    }).format(new Date(data.submissionDate))}
                  </td>

                  <td>
                    <Link
                      to={`/جميع-الدورات/${id}/الامتحانات/${examId}/التسليمات/${data.submissionId}/التقييمات`}>
                      <Button variant="primary d-flex justify-content-center align-items-center gap-2 w-100">
                        <span>فتح التقييم</span>
                        <i class="fa-solid fa-caret-left" />
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              عرض {pageNo * itemsPerPage + 1} الي{" "}
              {filteredExams.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredExams.length}{" "}
              من {filteredExams.length} عنصر
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                السابق
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button ${
                        pageNo === i ? "current" : ""
                      }`}
                      onClick={() => setPageNo(i)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                التالي
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamSubbmitions;
