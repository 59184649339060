import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
// import avatar from "../../../assets/images/avatar/1.jpg";

import { ThemeContext } from "../../../context/ThemeContext";
import Logout from "../nav/Logout";
import { GetNotifications } from "../../../API/GetNotifications";
import { IMAGES } from "../../constant/theme";
import { Row } from "rsuite";
import DateFormating from "../../../util/DateFormating";
import { ReadNotifications } from "../../../API/ReadNotifications";
import { queryClient } from "../../../App";
import { PROFILEIMG } from "../../../util/UploadToS3";
import { TokenDecode } from "../../../util/TokenDecode";

const Header = ({ onNote }) => {
  const { background, changeBackground } = useContext(ThemeContext);

  const [showAll, setShowAll] = useState(false);

  const handleThemeMode = () => {
    if (background.value === "dark") {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  };

  const {
    data: initialNotifications,
    isLoading: isLoadingInitial,
    isError: isErrorInitial,
    error: initialError,
  } = useQuery({
    queryKey: ["initialNotifications"],
    queryFn: () => GetNotifications(0),
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["allNotifications"],
      queryFn: ({ pageParam = 0 }) => GetNotifications(pageParam),
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.pageable.pageNumber + 1;
        return nextPage < lastPage.totalPages ? nextPage : undefined;
      },
      enabled: showAll,
    });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && showAll) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, showAll]);

  const { mutate } = useMutation({
    mutationFn: () => ReadNotifications(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["initialNotifications"] });
      queryClient.invalidateQueries({ queryKey: ["allNotifications"] });
    },
  });

  if (isLoadingInitial) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorInitial &&
    initialError.response.data.error !== "In valid email or password" &&
    initialError.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  const hasUnread = initialNotifications?.content.some(
    (notification) => !notification.read
  );

  const handleToggleClick = () => {
    if (hasUnread) {
      mutate();
    }
  };

  const userImg = TokenDecode(localStorage.getItem("token")).image;

  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown notification_dropdown">
                  <Link
                    to={"#"}
                    className={`nav-link bell dlab-theme-mode p-0 ${
                      background.value === "dark" ? "active" : ""
                    }`}
                    onClick={() => handleThemeMode()}>
                    <i
                      id="icon-light"
                      className="fas fa-sun"></i>
                    <i
                      id="icon-dark"
                      className="fas fa-moon"></i>
                  </Link>
                </li>

                <Dropdown
                  as="li"
                  onClick={handleToggleClick}
                  className="nav-item dropdown notification_dropdown">
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer icon-bell-effect"
                    variant=""
                    as="a">
                    <svg
                      id="icon-user"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-bell">
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                    {hasUnread && <div className="pulse-css"></div>}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    style={{ width: "400px" }}
                    className="mt-3 dropdown-menu dropdown-menu-right">
                    <ul
                      className="list-unstyled"
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}>
                      {!showAll
                        ? initialNotifications.content.map((notification) => (
                            <li
                              key={notification.id}
                              className="media dropdown-item align-items-center gap-3">
                              <span className="success">
                                <img
                                  src={`${PROFILEIMG}${notification.senderImage}`}
                                  alt="sender"
                                  onError={(e) =>
                                    (e.target.src = IMAGES.Avatpng1)
                                  }
                                  className="rounded-circle"
                                  width="35"
                                />
                              </span>
                              <div className="media-body">
                                <p>
                                  <strong>{notification.title}</strong>
                                </p>
                                <p>{notification.content}</p>
                              </div>
                              <span className="notify-time">
                                {DateFormating(notification.dateTime)}
                              </span>
                            </li>
                          ))
                        : data?.pages.map((page) =>
                            page.content.map((notification) => (
                              <li
                                key={notification.id}
                                className="media dropdown-item align-items-center gap-3">
                                <span className="success">
                                  <img
                                    src={`${PROFILEIMG}${notification.senderImage}`}
                                    alt="sender"
                                    onError={(e) =>
                                      (e.target.src = IMAGES.Avatpng1)
                                    }
                                    className="rounded-circle"
                                    width="35"
                                  />
                                </span>
                                <div className="media-body">
                                  <Link to="#">
                                    <p>
                                      <strong>{notification.title}</strong> -{" "}
                                      {notification.content}
                                    </p>
                                  </Link>
                                </div>
                                <span className="notify-time">
                                  {DateFormating(notification.dateTime)}
                                </span>
                              </li>
                            ))
                          )}
                      {isFetchingNextPage && showAll && (
                        <li className="media dropdown-item align-items-center gap-3">
                          <div className="d-flex justify-content-center w-100">
                            <span>Loading more...</span>
                          </div>
                        </li>
                      )}
                      <li ref={ref}></li>
                    </ul>
                    {!showAll && (
                      <Link
                        className="all-notification"
                        to="#"
                        onClick={() => setShowAll(true)}>
                        See all notifications <i className="ti-arrow-right" />
                      </Link>
                    )}
                    {showAll && !hasNextPage && (
                      <div className="d-flex justify-content-center w-100 p-2">
                        <span>No more notifications</span>
                      </div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown
                  as="li"
                  className="nav-item header-profile">
                  <Dropdown.Toggle
                    to={"#"}
                    className="nav-link i-false"
                    as="div">
                    <img
                      src={`${PROFILEIMG + userImg}?${new Date().getTime()}`}
                      width="20"
                      alt="userImg"
                      onError={(e) => (e.target.src = IMAGES.Avatpng1)}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-3 dropdown-menu dropdown-menu-right ">
                    <Link
                      to={"/الملف الشخصي-الخاص بي"}
                      className="dropdown-item ai-icon icon-bell-effect">
                      <span className="me-2">الملف الشخصي </span>
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-user">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle
                          cx="12"
                          cy="7"
                          r="4"></circle>
                      </svg>
                    </Link>

                    <Logout />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
