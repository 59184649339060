import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  Button,
  Dropdown,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { queryClient } from "../../../../../App";
import Swal from "sweetalert2";
import { GetCourseLessons } from "../../../../../API/Courses/SingleCourse/CourseLessons/GetCourseLessons";
import { DeleteCourseLesson } from "../../../../../API/Courses/SingleCourse/CourseLessons/DeleteCourseLesson";

import FilesList from "./FilesList";
const theadData = [
  { heading: "الرقم التعريفي" },
  { heading: "العنوان" },
  { heading: "رقم الدروس" },
  { heading: "تاريخ الافتتاح" },
  { heading: "عدد الدروس" },
  { heading: "الاجراء" },
];

function CouresLessons({ search, openDate }) {
  const [sort, setSortata] = useState(10);
  const [lessons, setLessons] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const activePag = useRef(0);
  const [pageNo, setPageNo] = useState(0);
  const [filteredCourses, setFilteredCourses] = useState([]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["CourseLessons", id],
    queryFn: () => GetCourseLessons(id),
  });

  const { mutate: DeleteLessonCousre } = useMutation({
    mutationFn: (lessonId) => DeleteCourseLesson(id, lessonId),
    onSuccess: () => {
      queryClient.invalidateQueries(["CourseLessons"]);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  function deleteLesson(id, filesCount) {
    if (filesCount > 0) {
      Swal.fire(
        "Error!",
        `يجب التأكد من حذف جميع الملفات المرفقة للدروس !!`,
        "error"
      );
      return;
    }
    if (filesCount === 0) {
      Swal.fire({
        title: "هل أنت متأكد من حذف هذا الدروس؟",
        text: "لن تستطيع استرجاع هذا الدروس مرة اخرى!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#df0d0d",
        cancelButtonColor: "#aaa",
        cancelButtonText: "الغاء",
        confirmButtonText: "نعم, تأكيد الحذف !",
      }).then((result) => {
        if (result.isConfirmed) {
          DeleteLessonCousre(id);
          Swal.fire("تم الحذف!", `تم الحذف بنجاح للدرس رقم ${id} !`, "success");
        }
      });
    }
  }

  const setFilesForLesson = (lessonId, newFiles) => {
    const updatedLessons = lessons.map((lesson) =>
      lesson.id === lessonId ? { ...lesson, files: newFiles } : lesson
    );
    setLessons(updatedLessons); // Update the state with the modified lessons
  };

  useEffect(() => {
    if (data) {
      const filtered = data.filter((lesson) => {
        const matchesTitle = search
          ? lesson.title.toLowerCase().includes(search.toLowerCase()) ||
            lesson.id.toString() === search
          : true;

        const matchesDate = openDate
          ? new Date(lesson.openTime).toISOString() >=
            new Date(openDate).toISOString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredCourses(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [data, search, id, openDate]);
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  let paggination =
    (!isLoading &&
      Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1)) ||
    [];

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <Button
        variant="link"
        onClick={decoratedOnClick}>
        {children}
      </Button>
    );
  }

  return (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                عرض
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {sort}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSortata("10")}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortata("20")}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortata("30")}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                عنصر
              </label>
            </div>
          </div>
          <Accordion>
            <table className="table">
              <thead>
                <tr>
                  {theadData.map((item, ind) => (
                    <th key={ind}>{item.heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!filteredCourses || filteredCourses.length === 0 ? (
                  <p className="pt-5">لا يوجد بيانات</p>
                ) : (
                  filteredCourses.map((lesson, index) => (
                    <Fragment key={lesson.id}>
                      <tr>
                        <td>
                          <CustomToggle
                            className="m-0 p-0"
                            eventKey={`${index}`}>
                            <i
                              class="fa-solid fa-chevron-down fa-lg"
                              style={{
                                color: "#888",
                                cursor: "pointer",
                                marginLeft: "-20px",
                              }}
                            />
                          </CustomToggle>
                          <strong>{lesson.id}</strong>
                        </td>
                        <td>
                          <strong>{lesson.title}</strong>
                        </td>
                        <td>
                          <strong>{lesson.lessonNo}</strong>
                        </td>
                        <td>
                          <strong>
                            {new Intl.DateTimeFormat("ar-EG", {
                              dateStyle: "full",
                            }).format(new Date(lesson.openTime))}
                          </strong>
                        </td>
                        <td>
                          <strong>{lesson.files.length}</strong>
                        </td>

                        <td className="d-flex justify-content-between align-items-center gap-2">
                          <Dropdown className="w-75">
                            <Dropdown.Toggle
                              className="w-100"
                              variant="primary">
                              اضافة ملفات{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                as={Link}
                                to={
                                  "/جميع-الدورات/" +
                                  id +
                                  "/اضافة-درس-فايل/" +
                                  lesson.id
                                }>
                                اضافة ملف
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={Link}
                                to={
                                  "/جميع-الدورات/" +
                                  id +
                                  "/اضافة-درس-فيديو/" +
                                  lesson.id
                                }>
                                اضافة فيديو
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <button
                            onClick={() =>
                              navigate(
                                `/جميع-الدورات/${id}/تعديل-درس/${lesson.id}`
                              )
                            }
                            className="btn sharp btn-primary">
                            <i className="fa fa-pencil" />
                          </button>
                          <button
                            onClick={() =>
                              deleteLesson(lesson.id, lesson.files.length)
                            }
                            className="btn sharp btn-danger">
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6">
                          <Accordion.Collapse eventKey={`${index}`}>
                            <FilesList
                              lesson={lesson}
                              setFiles={setFilesForLesson}
                            />
                          </Accordion.Collapse>
                        </td>
                      </tr>
                    </Fragment>
                  ))
                )}
              </tbody>
            </table>
          </Accordion>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              عرض {activePag.current * sort + 1} الي{" "}
              {isError
                ? 0
                : data?.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data?.length}{" "}
              من {data?.length} عنصر
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                السابق
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="#"
                    className={`paginate_button  ${
                      pageNo === i ? "current" : ""
                    } `}
                    onClick={() => setPageNo(i)}>
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < data?.totalPages - 1) setPageNo(pageNo + 1);
                }}>
                التالي
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouresLessons;
