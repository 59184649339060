import React, { useState, useEffect, Fragment } from "react";
import { Accordion, Button, Dropdown, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "rsuite";

import Swal from "sweetalert2";
import { GetAllAnnouncements } from "../../../API/Announcements/GetAllAnnouncements";
import { useMutation, useQuery } from "@tanstack/react-query";
import truncateString from "../../../util/truncateString";

import { queryClient } from "../../../App";
import { DeleteAnnouncements } from "../../../API/Announcements/DeleteAnnouncements";

const theadData = [
  { heading: "الرقم التعريفي" },
  { heading: "العنوان" },
  { heading: "الوصف" },
  { heading: "تاريخ الارسال" },
  { heading: "الاجراء" },
];

const AllAnnouncements = () => {
  const [search, setSearch] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [date, setDate] = useState("");
  const [localDate, setLocalDate] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [open, setOpen] = useState(true);
  const [showMessage, setShowMessage] = useState(null);
  const [filteredAnnouncments, setFilteredAnnouncments] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();

  const triggerSearch = () => {
    setSearch(localSearch);
    setDate(localDate);
  };

  const {
    data: allAnnouncements,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["AllAnnouncements,"],
    queryFn: () => GetAllAnnouncements(),
  });

  const { mutate: DeleteAnnouncement } = useMutation({
    mutationFn: (id) => DeleteAnnouncements(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["AllAnnouncements"]);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  useEffect(() => {
    if (allAnnouncements) {
      const filtered = allAnnouncements.filter((announcement) => {
        const matchesTitle = search
          ? announcement.title.toLowerCase().includes(search.toLowerCase()) ||
            announcement.id.toString() === search
          : true;
        const matchesDate = date
          ? new Date(announcement.dateTime).toDateString() ===
            new Date(date).toDateString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredAnnouncments(filtered);
      setPageNo(0);
    }
  }, [allAnnouncements, search, date]);

  if (isError) {
    error.response.data.error === "In valid email or password" &&
      error.response.status === 403 &&
      Swal.fire({
        title: "خطأ",
        text: "يجب اعادة تسجيل الدخول!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "العودة الي صفحة تسجيل الدخول",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  function deleteAnnouncement(id) {
    Swal.fire({
      title: "هل أنت متأكد من حذف هذا التنبيه؟",
      text: "لن تستطيع استعادة هذا التنبيه!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      cancelButtonText: "الغاء",
      confirmButtonText: "نعم, تأكيد الحذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAnnouncement(id);
        Swal.fire("تم الحذف!", `تم حذف التنبيه  رقم ${id} بنجاح`, "success");
      }
    });
  }

  const toggleMessage = (id) => {
    setShowMessage(showMessage === id ? null : id);
  };

  const totalPages = Math.ceil(filteredAnnouncments.length / itemsPerPage);
  const paginatedRates = filteredAnnouncments.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  function formatDate(dateInput) {
    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero and slice last 2 digits
    const day = ("0" + date.getDate()).slice(-2); // Add leading zero and slice last 2 digits

    return `${year}-${month}-${day}`;
  }

  function resetFilters() {
    setDate("");
    setSearch("");
    setLocalDate("");
    setLocalSearch("");
  }

  return (
    <>
      <PageTitle
        activeMenu={"جميع التنبيهات"}
        motherMenu={"التنبيهات"}
      />

      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>فلتر
          </div>
          <div className="tools">
            <Link
              to={"#"}
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>

        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">بحث</label>
                  <input
                    onChange={(e) => setLocalSearch(e.target.value)}
                    value={localSearch}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    id="exampleFormControlInput1"
                    placeholder="بحث باستخدام العنوان"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSearch(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">تاريخ الارسال</label>
                  <DatePicker
                    placeholder="اختر تاريخ الارسال"
                    className="form-control picker-suit border-0 p-0"
                    onChange={(e) => setLocalDate(formatDate(e))}
                    value={localDate ? new Date(localDate) : null}
                    format="yyyy-MM-dd"
                  />
                </div>
                <div className="col-xl-3 col-sm-6 align-self-end">
                  <button
                    onClick={triggerSearch}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    فلتر <i className="fa fa-search me-1" />
                  </button>
                  <button
                    onClick={resetFilters}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    حذف الفلتر
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="card">
        <div className="col-xl-12">
          <div className="card-body">
            {filteredAnnouncments.length === 0 ? (
              <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4">
                  لا يوجد بيانات
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <div
                  id="holidayList"
                  className="dataTables_wrapper no-footer">
                  <div className="justify-content-between d-sm-flex">
                    <div className="dataTables_length">
                      <label className="d-flex align-items-center">
                        عرض
                        <Dropdown className="search-drop">
                          <Dropdown.Toggle
                            as="div"
                            className="search-drop-btn">
                            {itemsPerPage}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {[10, 20, 30].map((number) => (
                              <Dropdown.Item
                                key={number}
                                onClick={() => setItemsPerPage(number)}>
                                {number}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        عنصر
                      </label>
                    </div>
                    <Button
                      variant="primary btn h-25"
                      onClick={() => navigate("/جميع-التنبيهات/اضافة-تنبيه")}>
                      + اضافة تنبيه
                    </Button>
                  </div>
                  <table
                    id="example4"
                    className="display dataTable no-footer w-100">
                    <thead>
                      <tr>
                        {theadData.map((item, ind) => (
                          <th key={ind}>{item.heading}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedRates.map((data) => (
                        <Fragment key={data.id}>
                          <tr>
                            <td>
                              <strong>{data.id}</strong>
                            </td>
                            <td>
                              <strong>{data.title}</strong>
                            </td>
                            <td>
                              <strong>
                                {truncateString(data.content, 10)}
                              </strong>
                            </td>

                            <td>
                              {new Intl.DateTimeFormat("ar-EG", {
                                dateStyle: "full",
                              }).format(new Date(data.dateTime))}
                            </td>

                            <td className="d-flex gap-3">
                              <Button
                                onClick={() => toggleMessage(data.id)}
                                className="btn sharp btn-primary d-flex justify-content-center align-items-center gap-2 w-75">
                                <span>عرض التفاصيل</span>
                                {showMessage === data.id ? (
                                  <i className="fa-solid fa-eye-slash" />
                                ) : (
                                  <i className="fa-solid fa-eye" />
                                )}
                              </Button>
                              {/* <button
                                onClick={() =>
                                  navigate(
                                    `/جميع-التنبيهات/${data.id}/edit-announcement`
                                  )
                                }
                                className="btn sharp btn-primary">
                                <i className="fa fa-pencil" />
                              </button> */}
                              <button
                                onClick={() => deleteAnnouncement(data.id)}
                                className="btn sharp btn-danger">
                                <i className="fa fa-trash" />
                              </button>
                            </td>
                          </tr>
                          {showMessage === data.id && (
                            <tr>
                              <td colSpan="6">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Body>
                                      {data.content}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      عرض {pageNo * itemsPerPage + 1} الي{" "}
                      {filteredAnnouncments.length > (pageNo + 1) * itemsPerPage
                        ? (pageNo + 1) * itemsPerPage
                        : filteredAnnouncments.length}{" "}
                      من {filteredAnnouncments.length} عنصر
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate">
                      <Link
                        className="paginate_button previous"
                        to="#"
                        onClick={() => {
                          if (pageNo > 0) setPageNo(pageNo - 1);
                        }}>
                        السابق
                      </Link>
                      <span>
                        {Array.from(
                          { length: totalPages },
                          (_, i) => i + 1
                        ).map((number) => (
                          <Link
                            key={number}
                            to="#"
                            className={`paginate_button ${
                              pageNo === number - 1 ? "current" : ""
                            }`}
                            onClick={() => setPageNo(number - 1)}>
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={() => {
                          if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                        }}>
                        التالي
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAnnouncements;
