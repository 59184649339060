import { useQuery } from "@tanstack/react-query";
import { GetStudentExams } from "../../../../API/Students/SingleSutdent/GetStudentExams";
import { Button, Dropdown, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import truncateString from "../../../../util/truncateString";
import { Formik } from "formik";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  score: Yup.number()
    .typeError("درجة الامتحان يجب أن يكون رقم")
    .required("يرجى أدخال درجة الامتحان")
    .integer("درجة الامتحان يجب أن يكون عددًا صحيحًا")
    .min(0, "يجب ان لا تكون اقل من 0")
    .max(100, "يجب ان لا تتجاوز درجة الامتحان 100"),
});

function Exams({ id, title, startDate }) {
  const [filteredExams, setFilteredExams] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [changeScore, setChangeScore] = useState(null);

  const theadData = [
    { heading: "الرقم التعريفي" },
    { heading: "عنوان الامتحان" },
    { heading: "التاريخ" },
    { heading: "النقاط" },
    { heading: "التقييم" },
    { heading: "صفحة الامتحان" },
  ];

  const {
    data: Exams,
    isLoading: isLoadingExams,
    isError: isErrorExams,
    error,
  } = useQuery({
    queryKey: ["Exams", id],
    queryFn: () => GetStudentExams(id),
  });

  useEffect(() => {
    if (Exams) {
      const filtered = Exams.filter((exam) => {
        const matchesTitle = title
          ? exam.id.toString().includes(title) ||
            exam.title.toLowerCase().includes(title)
          : true;
        const matchesDate = startDate
          ? new Date(exam.date).toDateString() ===
            new Date(startDate).toDateString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredExams(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [Exams, title, startDate]);

  const totalPages = Math.ceil(filteredExams.length / itemsPerPage);

  const paginatedExams = filteredExams.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  const handleOpenModal = (examId) => {
    setChangeScore(examId);
  };

  const handleCloseModal = () => {
    setChangeScore(null);
  };

  if (isLoadingExams) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorExams &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  return filteredExams.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        لا يوجد بيانات
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                عرض
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                عنصر
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedExams.map((data, ind) => (
                <tr key={data.id}>
                  <td>
                    <strong>{data.id}</strong>
                  </td>
                  <td>{truncateString(data.title, 4)}</td>
                  <td>
                    {new Intl.DateTimeFormat("ar-EG", {
                      dateStyle: "full",
                    }).format(new Date(data.date))}
                  </td>
                  <td>
                    {data.score || 0}%{" "}
                    <Button
                      onClick={() => handleOpenModal(data.id)}
                      className="ms-2"
                      variant="outline-primary">
                      <i className="fa fa-pencil" />
                      {"  "}تعديل
                    </Button>
                    {changeScore === data.id && (
                      <Modal
                        className="fade"
                        show={changeScore === data.id}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={handleCloseModal}>
                        <Modal.Header>
                          <Modal.Title>
                            تعديل نتيجة الامتحان رقم {data.id}
                          </Modal.Title>
                          {/* <Button
                            variant="close"
                            className="btn-close"
                            onClick={handleCloseModal}></Button> */}
                        </Modal.Header>
                        <Modal.Body>
                          <Formik
                            initialValues={{
                              score: "",
                            }}
                            validationSchema={loginSchema}
                            // // validateOnBlur={true}
                            // // validateOnChange={true}
                            onSubmit={(values, actions) => {
                              console.log(values);
                              handleCloseModal(data.id);
                            }}>
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="score">
                                          الدرجة
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          id="score"
                                          placeholder="ادخل الدرجة"
                                          type="text"
                                          name="score"
                                          className={`form-control ${
                                            touched.score
                                              ? errors.score
                                                ? "is-invalid"
                                                : "is-valid"
                                              : ""
                                          }`}
                                          value={values.score}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {touched.score && errors.score && (
                                          <div
                                            id="val-username1-error"
                                            className="invalid-feedback animated fadeInUp">
                                            {errors.score}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <Modal.Footer>
                                    <Button
                                      variant="danger light"
                                      onClick={() => handleCloseModal(data.id)}>
                                      اغلاق
                                    </Button>
                                    <Button
                                      type="submit"
                                      variant="primary">
                                      حفظ التعديل
                                    </Button>
                                  </Modal.Footer>
                                </form>
                              </div>
                            )}
                          </Formik>
                        </Modal.Body>
                      </Modal>
                    )}
                  </td>
                  <td>
                    <Link
                      to={
                        "/جميع-الدورات/" +
                        data.courseId +
                        "/الامتحانات/" +
                        data.id +
                        "/التسليمات/" +
                        data.feedbackId +
                        "/التقييمات"
                      }>
                      <Button variant="primary d-flex justify-content-center align-items-center gap-2 w-75">
                        <span>فتح التقييم</span>
                        <i className="fa fa-arrow-left" />
                      </Button>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={
                        "/جميع-الدورات/" +
                        data.courseId +
                        "/الامتحانات" +
                        "/" +
                        data.id
                      }>
                      <Button variant="primary d-flex justify-content-center align-items-center gap-2 w-100">
                        <span>فتح الامتحان</span>
                        <i className="fa fa-arrow-left" />
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              عرض {pageNo * itemsPerPage + 1} الي{" "}
              {filteredExams.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredExams.length}{" "}
              من {filteredExams.length} عنصر
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                السابق
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button ${
                        pageNo === i ? "current" : ""
                      }`}
                      onClick={() => setPageNo(i)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                التالي
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exams;
