import { useMutation, useQuery } from "@tanstack/react-query";
import { GetExamQuestions } from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamQuestions/GetExamQuestions";
import { Button, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { COURSEIMG } from "../../../../../../util/UploadToS3";
import { IMAGES } from "../../../../../constant/theme";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../../App";
import { DeleteExamQuesion } from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamQuestions/DeleteExamQuesion";
import { deleteFileDigitalOcean } from "../../../../../../util/accessDigitalOcean";

function ExamQuestions({ id, examId }) {
  const [pageNo, setPageNo] = useState(0);
  const itemsPerPage = 5;
  const navigate = useNavigate();

  const {
    data: ExamQuestions,
    isLoading: ExamQuestionsLoading,
    isError: ExamQuestionsError,
    error,
  } = useQuery({
    queryKey: ["ExamQuestions", id, examId],
    queryFn: () => GetExamQuestions(id, examId),
  });

  const { mutate: deleteExamQuestionMutation } = useMutation({
    mutationFn: (questionId) => DeleteExamQuesion(id, examId, questionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ExamQuestions"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete exam",
      });
    },
  });

  function deleteExam(id, img, type) {
    console.log(id, img, type);
    Swal.fire({
      title: "هل أنت متأكد من حذف هذا السؤال؟",
      text: "لن تستطيع استرجاع هذا السؤال مرة اخرى!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      cancelButtonText: "الغاء",
      confirmButtonText: "نعم, تأكيد الحذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExamQuestionMutation(id);
        deleteFileDigitalOcean(img, type);
        Swal.fire("تم الحذف!", `تم حذف السؤال رقم ${id} بنجاح`, "success");
      }
    });
  }

  if (ExamQuestionsLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    ExamQuestionsError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  const sortedExamQuestions = ExamQuestions.sort((a, b) =>
    a.questionNo > b.questionNo ? 1 : -1
  );

  const totalScore = sortedExamQuestions.reduce((total, item) => {
    return total + item.score;
  }, 0);
  const totalQuestions = sortedExamQuestions.length;
  let paggination =
    (!ExamQuestionsLoading &&
      Array(Math.ceil(ExamQuestions.length / itemsPerPage))
        .fill()
        .map((_, i) => i + 1)) ||
    [];
  const totalPages = Math.ceil(sortedExamQuestions.length / itemsPerPage);
  const paginatedExams = sortedExamQuestions.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  return (
    <div className="dataTables_wrapper no-footer">
      <h3 className="col-xl-12 text-center mt-4 pb-4 border-bottom  rounded">
        مجموع الاسئلة : {totalQuestions} / اجمالي الدرجات : {totalScore}
      </h3>
      {paginatedExams.map((question) =>
        question.questionType === "MCQ" ? (
          <div className="col-xl-12 mt-4  rounded p-1 py-3 border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <h4 className="text-primary ">
                    الدرجة : {question.score} / {totalScore}
                  </h4>
                  <p className="text-black fs-5">
                    رقم {question.questionNo} من {totalQuestions}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate(
                      `/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة/${question.id}/تعديل-سؤال`
                    )
                  }
                  className="btn sharp">
                  <i className="fa fa-pencil" />
                </Button>
                <Button
                  variant="danger"
                  className="btn sharp"
                  onClick={() =>
                    deleteExam(question.id, question.image, "IMG")
                  }>
                  <i className="fa fa-trash" />
                </Button>
              </div>
            </div>
            <p className="text-black fs-4">السؤال : {question.questionText}</p>

            <div className="d-flex justify-content-between text-black fs-5">
              <div>
                <p>الاجابات :</p>
                {question.choices.map((choice) => (
                  <div
                    key={choice.cid}
                    className="radio mb-1 ms-1">
                    <label className="form-check-label">
                      <input
                        type="radio"
                        name={"choices" + question.questionNo}
                        className={"form-check-input me-2"}
                        checked={choice.correct}
                      />
                      {choice.correct ? (
                        <span className="text-primary">
                          {choice.ctext} - الاجابة الصحيحة
                        </span>
                      ) : (
                        <span>{choice.ctext}</span>
                      )}
                    </label>
                  </div>
                ))}
              </div>
              {/* <div> */}
              <img
                className="question-img"
                src={COURSEIMG + "Exams/" + question.image}
                alt="QuestionImg"
                onError={(e) => (e.target.src = IMAGES.course1)}
              />
              {/* </div> */}
            </div>
          </div>
        ) : (
          <div className="col-xl-12 mt-4  rounded p-1 py-3 border-bottom">
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <h4 className="text-primary ">
                    الدرجة : {question.score} / {totalScore}
                  </h4>
                  <p className="text-black fs-5">
                    رقم {question.questionNo} من {totalQuestions}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-start">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate(
                      `/جميع-الدورات/${id}/الامتحانات/${examId}/الاسئلة/${question.id}/تعديل-سؤال`
                    )
                  }
                  className="btn sharp">
                  <i className="fa fa-pencil" />
                </Button>
                <Button
                  variant="danger"
                  className="btn sharp"
                  onClick={() =>
                    deleteExam(question.id, question.image, "IMG")
                  }>
                  <i className="fa fa-trash" />
                </Button>
              </div>
            </div>
            <div>
              <p className="text-black fs-4">
                السؤال : {question.questionText}
              </p>

              <div className="d-flex flex-column text-black fs-5">
                <div>
                  <img
                    className="question-img"
                    src={COURSEIMG + "Exams/" + question.image}
                    alt="QuestionImg"
                    onError={(e) => (e.target.src = IMAGES.course1)}
                  />
                </div>
                <div className="mt-3">
                  الاجابة الصحيحة : {question.modelAnswer}
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
        <div className="dataTables_info">
          عرض {pageNo * itemsPerPage + 1} الى{" "}
          {ExamQuestions.length > (pageNo + 1) * itemsPerPage
            ? (pageNo + 1) * itemsPerPage
            : ExamQuestions.length}{" "}
          من {ExamQuestions.length} عنصر
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="example5_paginate">
          <Link
            className="paginate_button previous disabled"
            to="#"
            onClick={() => {
              if (pageNo > 0) setPageNo(pageNo - 1);
            }}>
            السابق
          </Link>
          <span>
            {paggination.map((number, i) => (
              <Link
                key={i}
                to="#"
                className={`paginate_button ${pageNo === i ? "current" : ""}`}
                onClick={() => setPageNo(i)}>
                {number}
              </Link>
            ))}
          </span>
          <Link
            className="paginate_button next"
            to="#"
            onClick={() => {
              if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
            }}>
            التالي
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ExamQuestions;
