import React, { useState, useRef } from "react";
import { Badge, Dropdown, Row, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "rsuite";
import { useQuery } from "@tanstack/react-query";
import { IMAGES } from "../../constant/theme";
import Swal from "sweetalert2";
import { GetAllStudents } from "../../../API/Students/GetAllStudents";

const theadData = [
  { heading: "الرقم التعريفي" },
  { heading: "الاسم" },
  { heading: "الحالة" },
  { heading: "البريد الالكتروني" },
  { heading: "الهاتف" },
  { heading: "تم التحقق" },
  { heading: "تاريخ التسجيل" },
  { heading: "الاجراء" },
];

const AllStudents = () => {
  const [sort, setSortata] = useState(10);
  const [search, setSearch] = useState("");
  const [localSearch, setLocalSearch] = useState("");
  const [date, setDate] = useState("");
  const [localDate, setLocalDate] = useState("");
  const [localEducationLvl, setLocalEducationLvl] = useState("");
  const [educationLvl, setEducationLvl] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const triggerSearch = () => {
    setSearch(localSearch);
    setDate(localDate);
    setEducationLvl(localEducationLvl);
    setPageNo(0);
  };

  const {
    data: allStudents,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["AllStudents", search, date, educationLvl, pageNo, sort],
    queryFn: () => GetAllStudents(search, date, educationLvl, pageNo, sort),
  });

  const activePag = useRef(0);

  if (isError) {
    error.response.data.error === "In valid email or password" &&
      error.response.status === 403 &&
      Swal.fire({
        title: "خطأ",
        text: "يجب اعادة تسجيل الدخول!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "العودة الي صفحة تسجيل الدخول",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }
  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  let paggination =
    Array(Math.ceil(allStudents.totalPages))
      .fill()
      .map((_, i) => i + 1) || [];

  function formatDate(dateInput) {
    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero and slice last 2 digits
    const day = ("0" + date.getDate()).slice(-2); // Add leading zero and slice last 2 digits

    return `${year}-${month}-${day}`;
  }

  function resetFilters() {
    setDate("");
    setSearch("");
    setLocalDate("");
    setLocalSearch("");
    setLocalEducationLvl("");
  }

  return (
    <>
      <PageTitle
        activeMenu={"جميع الطلاب"}
        motherMenu={"الطلاب"}
      />

      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>فلتر
          </div>
          <div className="tools">
            <Link
              to={"#"}
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>

        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">بحث</label>
                  <input
                    onChange={(e) => setLocalSearch(e.target.value)}
                    value={localSearch}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    id="exampleFormControlInput1"
                    placeholder="بحث باستخدام الاسم"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSearch(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="col-xl-3 col-sm-6 d-flex flex-column">
                  <label className="form-label">تاريخ التسجيل</label>
                  <DatePicker
                    placeholder="تاريخ التسجيل"
                    className="picker-suit"
                    onChange={(e) => setLocalDate(formatDate(e))}
                    value={localDate ? new Date(localDate) : null}
                    format="yyyy-MM-dd"
                  />
                </div>
                <div className="col-xl-3 col-sm-6 align-self-end">
                  <button
                    onClick={triggerSearch}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    فلتر <i className="fa fa-search me-1" />
                  </button>
                  <button
                    onClick={resetFilters}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    حذف الفلتر
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <Row>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane
                eventKey="List"
                className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">جميع الطلاب</h4>
                    <div className="d-flex gap-3 align-items-center">
                      {/* <label
                        to={"/add-student"}
                        htmlFor="csv"
                        className="btn btn-primary">
                        <MdOutlineFileUpload size={20} /> CSV
                        <input
                          type="file"
                          className="d-none"
                          onChange={handleFileChange}
                          id="csv"
                        />
                      </label> */}
                      <Link to={"اضافة-طالب"}>
                        <button className="btn btn-primary">
                          + اضافة طالب جديد
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="holidayList"
                        className="dataTables_wrapper no-footer">
                        <div className="justify-content-between d-sm-flex">
                          <div className="dataTables_length">
                            <label className="d-flex align-items-center">
                              عرض
                              <Dropdown className="search-drop">
                                <Dropdown.Toggle
                                  as="div"
                                  className="search-drop-btn">
                                  {sort}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => setSortata("10")}>
                                    10
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setSortata("20")}>
                                    20
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setSortata("30")}>
                                    30
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              عنصر
                            </label>
                          </div>
                        </div>
                        <table
                          id="example4"
                          className="display dataTable no-footer w-100">
                          <thead>
                            <tr>
                              {theadData.map((item, ind) => (
                                <th key={ind}>{item.heading}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {!allStudents ||
                            allStudents.content.length === 0 ? (
                              <p className="pt-5">لا يوجد بيانات</p>
                            ) : (
                              allStudents.content.map((data, ind) => (
                                <tr
                                  onClick={() =>
                                    navigate("/جميع-الطلاب/" + data.id)
                                  }
                                  key={ind}>
                                  <td>
                                    <strong>{data.id}</strong>
                                  </td>
                                  <td>
                                    <img
                                      className="rounded-circle me-2"
                                      width="35"
                                      src={data.image}
                                      alt="Person"
                                      onError={(e) => {
                                        e.target.src = IMAGES.Avatpng1;
                                      }}
                                    />
                                    {data.firstName + " " + data.lastName}
                                  </td>
                                  <td>
                                    <Badge
                                      bg=""
                                      className={
                                        data.active
                                          ? `badge-success light`
                                          : `badge-danger light`
                                      }>
                                      {data.active ? "نشط" : "غير نشط"}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      <strong>{data.email}</strong>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      <strong>+{data.phone}</strong>
                                    </Link>
                                  </td>
                                  <td>
                                    {data.verified === true ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#7ED321"
                                        class="bi bi-check-circle-fill"
                                        viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#FF1616"
                                        class="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                      </svg>
                                    )}
                                  </td>

                                  <td>
                                    {new Intl.DateTimeFormat("ar-EG", {
                                      dateStyle: "full",
                                    }).format(new Date(data.registerDate))}
                                  </td>
                                  <td className="d-flex justify-content-between align-items-center ">
                                    <Link
                                      to={"/جميع-الطلاب/" + data.id}
                                      className="btn sharp btn-primary d-flex justify-content-center align-items-center gap-2 w-100">
                                      <span>فتح</span>
                                      <i className="fa fa-arrow-left" />
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                          <div className="dataTables_info">
                            عرض {activePag.current * sort + 1} الى{" "}
                            {isError
                              ? 0
                              : allStudents?.content.length >
                                (activePag.current + 1) * sort
                              ? (activePag.current + 1) * sort
                              : allStudents?.content.length}{" "}
                            من {allStudents?.content.length} عنصر
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate">
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={() => {
                                if (pageNo > 0) setPageNo(pageNo - 1);
                              }}>
                              السابق
                            </Link>
                            <span>
                              {paggination.map((number, i) => (
                                <Link
                                  key={i}
                                  to="#"
                                  className={`paginate_button  ${
                                    pageNo === i ? "current" : ""
                                  } `}
                                  onClick={() => setPageNo(i)}>
                                  {number}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={() => {
                                if (pageNo < allStudents?.totalPages - 1)
                                  setPageNo(pageNo + 1);
                              }}>
                              التالي
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Row>
    </>
  );
};

export default AllStudents;
