export const MenuList = [
  //Dashboard
  {
    title: "القائمة الرئيسية",
    classsChange: "menu-title",
    extraclass: "first",
  },
  //Dashboard
  {
    title: "لوحة التحكم",
    // classsChange: "mm-collapse",
    iconStyle: <i className="la la-home" />,
    to: "لوحة-التحكم",
    // content: [
    //   {
    //       title: "Dashboard 1",
    //       to: "dashboard",
    //   },
    //   {
    //     title: "Dashboard 2",
    //     to: "index-2",
    //   },
    //   {
    //     title: "Dashboard 3",
    //     to: "index-3",
    //   },
    // ],
  },

  //Student
  {
    title: "الطلاب",
    // classsChange: "mm-collapse",
    iconStyle: <i className="la la-users" />,
    to: "جميع-الطلاب",
    // content: [
    //   {
    //     title: "All Students",
    //   },
    //   {
    //     title: "Add Students",
    //     to: "add-student",
    //   },
    //   {
    //     title: "Edit Students",
    //     to: "edit-student",
    //   },
    //   {
    //     title: "About Students",
    //     to: "about-student",
    //   },
    // ],
  },
  {
    title: "التسجيلات",
    // classsChange: "mm-collapse",
    iconStyle: <i class="las la-stopwatch"></i>,
    to: "جميع-التسجيلات",
    // content: [
    //   {
    //     title: "All Students",
    //   },
    //   {
    //     title: "Add Students",
    //     to: "add-student",
    //   },
    //   {
    //     title: "Edit Students",
    //     to: "edit-student",
    //   },
    //   {
    //     title: "About Students",
    //     to: "about-student",
    //   },
    // ],
  },
  {
    title: "الرسائل",
    // classsChange: "mm-collapse",
    iconStyle: <i class="las la-inbox"></i>,
    to: "جميع-الرسائل",
    // content: [
    //   {
    //     title: "All Students",
    //   },
    //   {
    //     title: "Add Students",
    //     to: "add-student",
    //   },
    //   {
    //     title: "Edit Students",
    //     to: "edit-student",
    //   },
    //   {
    //     title: "About Students",
    //     to: "about-student",
    //   },
    // ],
  },

  //Courses
  {
    title: "الدورات التعليمية",
    // classsChange: "mm-collapse",
    iconStyle: <i class="lab la-youtube"></i>,
    to: "جميع-الدورات",
    // content: [
    //   {
    //     title: "All Courses",
    //   },
    //   {
    //     title: "Add Courses",
    //     to: "add-courses",
    //   },
    //   {
    //     title: "Edit Courses",
    //     to: "edit-courses",
    //   },
    //   {
    //     title: "About Courses",
    //     to: "about-courses",
    //   },
    // ],
  },

  //Announcements
  {
    title: "التنبيهات",
    to: "جميع-التنبيهات",
    iconStyle: <i class="la la-bell" />,
  },

  //Management
  {
    title: "تتبع الاحداث",
    to: "ادارة-الاحداث",
    iconStyle: <i className="la la-calendar" />,
  },
  //Transactions
  {
    title: "المعاملات",
    to: "جميع-المعاملات",
    iconStyle: <i class="las la-exchange-alt"></i>,
  },

  //My Profile
  {
    title: "الملف الشخصي",
    // classsChange: "mm-collapse",
    iconStyle: <i className="la la-user" />,
    to: "الملف-الشخصي-الخاص بي",
    // content: [
    //   {
    //     title: "All Professor",
    //     to: "all-professors",
    //   },
    //   {
    //     title: "Add Professor",
    //     to: "add-professor",
    //   },
    //   {
    //     title: "Edit Professor",
    //     to: "edit-professor",
    //   },
    //   {
    //     title: "Professor Profile",
    //   },
    // ],
  },
];
