import React from "react";
import { DatePicker } from "rsuite";
import * as Yup from "yup";
import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../App";
import { AddNewCourse } from "../../../API/Courses/AddNewCourse";
import Swal from "sweetalert2";
import Select, { components } from "react-select";
import { GetTeacherCourses } from "../../../API/Announcements/GetTeacherCourses";
import { AddNewAnnouncment } from "../../../API/Announcements/AddNewAnnouncment";

const loginSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "يجب أن يتكون العنوان من 3 أحرف على الأقل")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "يجب أن يحتوي العنوان على أحرف أو أرقام ويمكن أن يشمل شرطات أو شرطات سفلية"
    )
    .required("يرجى إدخال العنوان"),
  content: Yup.string()
    .min(3, "يجب أن يتكون المحتوى من 3 أحرف على الأقل")
    .required("يرجى إدخال محتوى الإعلان"),
  dateTime: Yup.date().required("يرجى اختيار التاريخ والوقت"),
  coursesIds: Yup.array()
    .of(Yup.string().required("يجب ألا يكون معرف الكورس فارغًا"))
    .min(1, "يجب اختيار كورس واحد على الأقل")
    .required("يرجى اختيار كورس واحد على الأقل"),
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor:
      state.touched && state.error
        ? "red"
        : state.touched && !state.error
        ? "#7ed321"
        : provided.borderColor,
    "&:hover": {
      borderColor:
        state.touched && state.error
          ? "red"
          : state.touched && !state.error
          ? "#7ed321"
          : provided.borderColor,
    },
    boxShadow:
      state.touched && state.error
        ? "0 0 0 1px red"
        : state.touched
        ? "0 0 0 1px #7ed321"
        : "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}>
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

const EditAnnouncement = () => {
  const navigate = useNavigate();
  const {
    data: TeacherCourses,
    isLoading: isTeacherCoursesLoading,
    isError: isTeacherCoursesError,
  } = useQuery({
    queryKey: ["TeacherCourses"],
    queryFn: () => GetTeacherCourses(),
  });

  const { mutate } = useMutation({
    mutationFn: (data) => AddNewAnnouncment(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["AllAnnouncements"]);
      Swal.fire({
        icon: "success",
        title: "تم بنجاح",
        text: "Announcement Added Successfully",
      });
      navigate("/جميع-التنبيهات");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "خطأ...",
        text: "حدث خطأ!",
      });
    },
  });

  const COURSES =
    !isTeacherCoursesLoading &&
    !isTeacherCoursesError &&
    TeacherCourses.map((item) => {
      return { value: item.id, label: item.title };
    });

  const CheckboxOption = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    );
  };

  return (
    <>
      <PageTitle
        activeMenu={"Add Announcement"}
        motherMenu={"Announcements"}
        goBack
        goTo={"/جميع-التنبيهات"}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Announcement Details</h4>
            </div>

            <Formik
              initialValues={{
                title: "",
                content: "",
                dateTime: "",
                coursesIds: [],
              }}
              validationSchema={loginSchema}
              onSubmit={(values, actions) => {
                console.log(values);
                mutate(values, {
                  onSuccess: (data) => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Announcement Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Announcement Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="content">
                            Announcement Content
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="content"
                            placeholder="Enter Announcement Content"
                            type="text"
                            name="content"
                            className={`form-control ${
                              touched.content
                                ? errors.content
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.content}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.content && errors.content && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.content}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="coursesIds"
                            className="form-label">
                            Send announcement to courses
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="coursesIds"
                            id="coursesIds"
                            placeholder="Select Courses"
                            isSearchable={true}
                            options={COURSES}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option: CheckboxOption,
                              ClearIndicator,
                            }}
                            styles={{
                              ...customStyles,
                              clearIndicator: ClearIndicatorStyles,
                            }}
                            className={`${
                              touched.coursesIds
                                ? errors.coursesIds
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            onChange={(option) => {
                              const selectedIds = option.map(
                                (opt) => opt.value
                              );
                              setFieldValue("coursesIds", selectedIds);
                              setFieldTouched("coursesIds", true, false); // mark as touched but do not validate yet
                              validateField("coursesIds"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("coursesIds", true)}
                          />
                          {touched.coursesIds && errors.coursesIds && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.coursesIds}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="dateTime">
                            Announcement Date and Time
                          </label>
                          <DatePicker
                            id="dateTime"
                            placeholder="Select Announcement Time"
                            format="yyyy-MM-dd HH:mm:ss"
                            style={customStyles}
                            value={values.dateTime}
                            onChange={(value) =>
                              setFieldValue("dateTime", value)
                            }
                            block
                          />
                          {touched.dateTime && errors.dateTime && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.dateTime}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAnnouncement;
