import { useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { Accordion, Button, Dropdown, Row } from "react-bootstrap";
import { IMAGES } from "../../../../constant/theme";
import { Link } from "react-router-dom";
import { GetCourseRates } from "../../../../../API/Courses/SingleCourse/CourseRates/GetCourseRates";
import { IoStar } from "react-icons/io5";

function AllCourseRates({ id, search, rateDate }) {
  const [filteredRates, setFilteredRates] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showMessage, setShowMessage] = useState(null);

  const theadData = [
    { heading: "الرقم التعريفي" },
    { heading: "رقم الطالب التعريفي" },
    { heading: "اسم الطالب" },
    { heading: "تاريخ التقييم" },
    { heading: "التقييم" },
    { heading: "الرسالة" },
  ];

  const toggleMessage = (id) => {
    setShowMessage(showMessage === id ? null : id);
  };

  const {
    data: ExamRates,
    isLoading: isLoadingRates,
    isError: isErrorRates,
    error,
  } = useQuery({
    queryKey: ["CourseRates", id],
    queryFn: () => GetCourseRates(id),
  });

  useEffect(() => {
    if (ExamRates) {
      const filtered = ExamRates.filter((rate) => {
        const matchesTitle = search
          ? rate.studentId.toString() === search ||
            rate.id.toString() === search
          : true;
        const matchesDate = rateDate
          ? new Date(rate.date).toDateString() ===
            new Date(rateDate).toDateString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredRates(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [ExamRates, search, rateDate]);

  const totalPages = Math.ceil(filteredRates.length / itemsPerPage);
  const paginatedRates = filteredRates.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  if (isLoadingRates) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">تحميل...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorRates &&
    error.response.data.error !== "Invalid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
          حدث خطأ
        </div>
      </Row>
    );
  }

  return filteredRates.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4">
        لا يوجد بيانات
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                عرض
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {[10, 20, 30].map((number) => (
                      <Dropdown.Item
                        key={number}
                        onClick={() => setItemsPerPage(number)}>
                        {number}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                عنصر
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedRates.map((data) => (
                <Fragment key={data.id}>
                  <tr>
                    <td>
                      <strong>{data.id}</strong>
                    </td>
                    <td>
                      <strong>{data.studentId}</strong>
                    </td>
                    <td>
                      <img
                        className="rounded-circle me-2"
                        width="35"
                        src={data.studentImage}
                        alt="Person"
                        onError={(e) => {
                          e.target.src = IMAGES.Avatpng1;
                        }}
                      />
                      {data.studentName}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("ar-EG", {
                        dateStyle: "full",
                      }).format(new Date(data.date))}
                    </td>
                    <td>
                      <IoStar
                        size={"1.4rem"}
                        color="#FFD600"
                      />{" "}
                      {data.rate}
                    </td>
                    <td className="ms-5">
                      <Button
                        onClick={() => toggleMessage(data.id)}
                        className="btn sharp btn-primary d-flex justify-content-center align-items-center gap-2 w-75">
                        <span>عرض الرسالة</span>
                        {showMessage === data.id ? (
                          <i className="fa-solid fa-eye-slash" />
                        ) : (
                          <i className="fa-solid fa-eye" />
                        )}
                      </Button>
                    </td>
                  </tr>
                  {showMessage === data.id && (
                    <tr>
                      <td colSpan="6">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Body>{data.comment}</Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              عرض {pageNo * itemsPerPage + 1} الي{" "}
              {filteredRates.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredRates.length}{" "}
              من {filteredRates.length} عنصر
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                السابق
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number) => (
                    <Link
                      key={number}
                      to="#"
                      className={`paginate_button ${
                        pageNo === number - 1 ? "current" : ""
                      }`}
                      onClick={() => setPageNo(number - 1)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                التالي
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCourseRates;
